import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";

import { ExitToApp, Edit, ControlPoint } from "@material-ui/icons/";

import KatchPartyList from "./KatchPartyList";

const useStyles = makeStyles(theme => ({
  container: {
    overflowY: "overlay",
    backgroundColor: "rgb(0,0,0,0.9)",
    padding: "40px",
    height: "100%",
    width: "380px",
    cursor: "default"
  },

  root: {
    width: "100%",
    maxWidth: 360,
    padding: "0"
  },
  item: {
    borderBottom: "1px dashed gray",
    marginLeft: "0",
    paddingLeft: "5px",
    color: "Silver",
    "&:hover": {
      backgroundColor: `transparent`
    }
  },
  titleItem: {
    margin: "15px 0 0 0",
    padding: "0 15px 0 5px"
  },
  title: {
    color: "rgb(119, 254, 57, 1)"
  },
  header: {
    fontSize: "1.5em",
    lineHeight: "1.5em",
    color: "white"
  },
  exitIcon: {
    color: "rgb(119, 254, 57, 1)",
    marginBottom: "20px",
    fontSize: "2.5em",
    cursor: "pointer"
  },
  editIcon: {
    color: "rgb(119, 254, 57, 1)",
    cursor: "pointer"
  },
  itemText: {
    letterSpacing: "1px",
    color: "white"
  },
  itemIcon: {
    color: "white",
    cursor: "pointer",
    transform: "rotate(45deg)"
  },
  addPeopleContainer: {
    margin: "10px 0",
    padding: "0 0 10px 0",
    borderBottom: "1px dashed gray",
    color: "rgb(119, 254, 57, 1)"
  },
  addPeopleIcon: {
    marginRight: "10px"
  },
  addPeopleText: {
    fontSize: "0.75em !important",
    color: "rgb(119, 254, 57, 1)",
    letterSpacing: "1px"
  },
  "MuiListItem-button:hover": {
    backgroundColor: "red"
  },
  "MuiTouchRipple-rippleVisible": {
    color: "red !important"
  },
  buttons: {
    margin: "30px 0"
  },
  launchParty: {
    fontSize: "0.65em",
    color: "white",
    backgroundColor: theme.colors.primaryDarkPurple,
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    width: "47.5%",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4B1684",
      border: "1px solid green",
      borderColor: theme.colors.primaryGreen,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684",
    },
  },
  saveParty: {
    fontSize: "0.65em",
    color: "white",
    backgroundColor: "transparent",
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    width: "47.5%",
    height: "40px",
    textTransform: "none",
    border: "2px solid white",
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: theme.colors.primaryGreen,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684",
    },
  },
}));

export default function DrawerMenu(props) {
  const classes = useStyles(props);
  const { toggleDrawer } = props;

  const users = [
    {
      name: "Kyle Haley",
      id: 0,
      isActive: true
    },
    {
      name: "Jacob C",
      id: 1,
      isActive: true
    },
    {
      name: "Jeff Bridges",
      id: 2,
      isActive: true
    },
    {
      name: "John Smith",
      id: 3,
      isActive: true
    },
    {
      name: "Jimmy Neutron",
      id: 4,
      isActive: true
    }
  ];

  return (
    <div className={classes.container}>
      <ExitToApp
        className={classes.exitIcon}
        onClick={() => toggleDrawer(false)}
      />
      <div className={classes.header}>Welcome, Andrew</div>
      <List className={classes.root}>
        <ListItem className={classes.titleItem}>
          <ListItemText
            className={classes.title}
            primary={"Katch Movies 2020"}
          />
          <Edit className={classes.editIcon} />
        </ListItem>
      </List>
      <List className={classes.root}>
        <Divider variant="inset" component="li" />
        {users.map((user, index) => (
          <ListItem key={user.id} className={classes.item}>
            <ListItemText primary={user.name} className={classes.itemText} />
            <ControlPoint className={classes.itemIcon} />
          </ListItem>
        ))}
      </List>
      <Divider light />
      <List className={classes.root}>
        <ListItem button className={classes.addPeopleContainer}>
          <ControlPoint className={classes.addPeopleIcon} />
          <ListItemText>
            {" "}
            <Typography component="span" className={classes.addPeopleText}>
              Add people to your KatchParty
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
      <Grid container item xs={12}  className={classes.buttons} style={{ display: "flex" }} justify={"space-between"}>
        <Button
          className={classes.launchParty}
          variant="contained"
          disableRipple
          disableelevation="true"
        >
          Launch KatchParty
        </Button>
        <Button
          className={classes.saveParty}
          variant="contained"
          disableRipple
          disableelevation="true"
        >
          Save This Party
        </Button>
      </Grid>
      <Divider />
      <KatchPartyList
        list={[
          { title: "Katch Movies 2019", names: ["jim", "joe", "bill"] },
          { title: "Cool Katch Movies 2020", names: ["jim", "joe", "bill"] }
        ]}
      />
    </div>
  );
}
