import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import PasswordModal from "../components/PasswordModal";

import { useUser } from "../context/UserContext";
import { updateUserImage, updateUserAccount } from "../utils/api";

import {
  Avatar,
  Button,
  Dialog,
  TextField,
  Typography,
  Grid,
  FormControl,
  CircularProgress
} from "@material-ui/core";

import { Edit } from "@material-ui/icons/";

const useStyles = makeStyles(theme => ({
  container: {
    // width: "55%",
  },
  form: {
    display: "flex",
    flex: "1",
    marginTop: "15px",
    alignItems: "center",
    justifyContent: "center"
  },
  fields: {
    width: "480px"
  },
  avatarContainer: {
    width: "auto",
    padding: "0px 30px"
  },
  textField: {
    color: "white",
    borderColor: "white",
    borderWidth: "10px",
    "&.focused": {
      color: "red"
    }
  },
  text: {
    fontSize: "1.2em",
    color: "white",
    borderColor: "#ced4da",
    marginBottom: "30px"
  },
  editAccount: {
    fontSize: "0.75em",
    color: "white",
    backgroundColor: theme.colors.primaryLightPurple,
    border: "2px solid white",
    fontFamily: "Samsung Sharp Sans Bold",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "47.5%",
    height: "60px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: theme.colors.primaryLightPurple,
      border: "2px solid green",
      borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: "#005cbf"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  editPassword: {
    fontSize: "0.75em",
    color: "white",
    backgroundColor: theme.colors.primaryDarkPurple,
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "47.5%",
    height: "60px",
    textTransform: "none",
    border: "2px solid white",
    "&:hover": {
      color: "white",
      backgroundColor: theme.colors.primaryDarkPurple,
      border: "2px solid white",
      borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: theme.colors.primaryGreen
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  uploadPhotoButton: {
    fontSize: "0.75em",
    color: "white",
    margin: "25px 0 0 0",
    backgroundColor: theme.colors.primaryDarkPurple,
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "220px",
    height: "60px",
    textTransform: "none",
    border: "2px solid white",
    "&:hover": {
      color: theme.colors.primaryGreen,
      backgroundColor: theme.colors.primaryDarkPurple,
      borderColor: "white",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: theme.colors.primaryGreen
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  // TODO: Make own button component
  removePhotoButton: {
    fontSize: "0.75em",
    color: "white",
    backgroundColor: "transparent",
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    letterSpacing: "1.5px",
    height: "60px",
    textTransform: "none",
    textDecoration: "underline",
    border: "none",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      // border: "1px solid green",
      // borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none"
      // backgroundColor: "#4B1684",
      // borderColor: "#005cbf"
    },
    "&:focus": {
      // boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  avatar: {
    width: "140px",
    height: "140px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  spinner: {
    color: theme.colors.primaryGreen
  }
}));

// const reducer = (state, { field, value }) => {
//   return {
//     ...state,
//     [field]: value,
//   };
// };

// const handleFilterCountries = event => {
//   const { name, value } = event.target;
//   setFilterInput({ [name]: value });
// };

export default function MyAccount(props) {
  const classes = useStyles(props);

  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isProcessingEdit, setProcessingEdit] = useState(false);
  const [accountUser, setUserSettings] = useState({});
  const { user, setCurrentUser } = useUser();

  useEffect(() => {
    console.log("Account loading user", user);
    if (user) {
      setUserSettings({
        ...accountUser,
        name: user.name,
        email: user.email,
        age: user.age,
        zipcode: user.zipcode,
        gender: user.gender
      });
    }
  }, []);

  const maskPassword = length => {
    let pw = "";
    for (let i = 0; i < length; i++) {
      pw += "*";
    }
    return pw;
  };

  const handleOverlayClose = () => {
    setOverlayOpen(false);
  };

  const handleOverlayOpen = () => {
    setOverlayOpen(true);
  };

  const handleCancelOrPassword = () => {
    if (isEditing) {
      setEditing(false);
    } else {
      setOverlayOpen(true);
    }
  };

  const handleSubmitData = async (e) => {
    if (isEditing) {
      setProcessingEdit(true);
      console.log("Updating User: ", user, accountUser)
      // const response = await updateUserImage(accountUser);
      await updateUserAccount(user.uid, accountUser);
      // setCurrentUser({
      //   name: response.Name,
      //   age: response.Age,
      //   email: response.Email,
      //   gender: response.Gender,
      //   services: response.Services,
      //   zipcode: response.Zipcode
      // })
      setProcessingEdit(false);
      setEditing(!isEditing);
    } else {
      setEditing(!isEditing);
    }
  };

  const handleChange = event => {
    setUserSettings({
      ...accountUser,
      [event.target.name]: event.target.value
    });
  };
  // update user
  // currentUser.updateProfile({
  //   displayName: “Bill Murray”,
  //   photoURL: “http://www.fillmurray.com/g/200/300"
  //  });

  // currentUser.updateEmail(“bill-murray@gmail.com”);
  // currentUser.updatePassword(“some-new-password-string”);

  // useEffect(() => {
  //   // fetchUserAccountInfo()
  //   console.log("Fetching info...", user);
  // }, [user]);

  // const [state, dispatch] = useReducer(reducer, user);

  // const onChange = (evt) => {
  //   dispatch({ field: evt.target.id, value: evt.target.value });
  // };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <Grid container className={classes.container} justify={"center"}>
        <Grid item container className={classes.fields}>
          {/* TODO ADD VALIDATION */}
          <Grid item xs={12}>
            <TextField
              label="Name"
              id="name"
              name="name"
              defaultValue={accountUser.name}
              value={accountUser.name}
              onChange={handleChange}
              fullWidth
              InputProps={{
                className: classes.text,
                disabled: !isEditing
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              id="email"
              name="email"
              value={accountUser.email}
              fullWidth
              onChange={handleChange}
              InputProps={{
                className: classes.text,
                disabled: !isEditing
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Age"
              id="age"
              name="age"
              value={accountUser.age}
              fullWidth
              onChange={handleChange}
              InputProps={{
                className: classes.text,
                disabled: !isEditing
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            style={{ marginRight: "auto", marginLeft: "-10px" }}
          ></Grid>
          <Grid item xs={5}>
            <TextField
              label="Gender"
              id="gender"
              name="gender"
              value={accountUser.gender}
              fullWidth
              onChange={handleChange}
              InputProps={{
                className: classes.text,
                disabled: !isEditing
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Zipcode"
              id="zipcode"
              name="zipcode"
              value={accountUser.zipcode}
              fullWidth
              onChange={handleChange}
              InputProps={{
                className: classes.text,
                disabled: !isEditing
              }}
              inputProps={{maxLength: 5}}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ display: "flex", paddingTop: "25px" }}
            justify="space-between"
          >
            {/* <Button
              className={classes.editPassword}
              variant="contained"
              disableRipple
              disableelevation="true"
              type="button"
              onClick={handleCancelOrPassword}
              disabled={isProcessingEdit}
            >
              {isEditing ? "Cancel" : "Edit Password"}
            </Button> */}
            {!isProcessingEdit &&<Button
              className={classes.editAccount}
              variant="contained"
              disableRipple
              disableelevation="true"
              type="button"
              onClick={handleSubmitData}
            >
              {isEditing ? "Save Changes" : "Edit Account Info"}
            </Button>}
            {isProcessingEdit && <Button
              className={classes.editAccount}
              variant="contained"
              disableRipple
              disableelevation="true"
              type="button"
              onClick={handleSubmitData}
            >
              <CircularProgress className={classes.spinner} />
            </Button>}
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={classes.avatarContainer}
        >
          <Avatar alt="user-avatar" src="" className={classes.avatar} />
          <Button
            className={classes.uploadPhotoButton}
            variant="contained"
            disableRipple
            disableelevation="true"
          >
            Upload Photo
          </Button>
          <Button
            className={classes.removePhotoButton}
            variant="contained"
            disableRipple
            disableelevation="true"
          >
            Remove Photo
          </Button>
        </Grid>
      </Grid>
      <Dialog
        disableBackdropClick
        open={isOverlayOpen}
        className={classes.backdrop}
      >
        <PasswordModal closeOverlay={handleOverlayClose} />
      </Dialog>
    </form>
  );
}
