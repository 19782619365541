import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Grid, Zoom } from "@material-ui/core";

import PosterSpinner from "../components/PosterSpinner";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    color: "white",
    padding: "6px 16px",
    justifyContent: "center",
    textAlign: "center"
  },
  text: {
    fontSize: "1.5em"
  },
  loading: {
    textAlign: "center"
  }
}));

const MoviesLoading = ({ props }) => {
  const classes = useStyles(props);

  useEffect(() => {
    console.log("LOADING MORE MOVIES...");
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {/* <Typography className={classes.loading} component={"div"}>
          There may have been a problem loading.
        </Typography>
        <Button style={{ width: "150px", color: "#77FE39", margin: "0 auto" }}>
          Retry
        </Button> */}
      </Grid>
      <Grid item xs={12}>
        <PosterSpinner />
      </Grid>
    </Grid>
  );
};

export default MoviesLoading;
