import React, { useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import unionBy from "lodash/unionBy";
import uniqBy from "lodash/uniqBy";
import { makeStyles } from "@material-ui/core/styles";

import MoviesRate from "../components/MoviesRate";
import MoviesLoading from "../components/MoviesLoading";
import MoviesLogin from "../components/MoviesLogin";
import MoviesEmpty from "../components/MoviesEmpty";

// import MainOverlay from "./MainOverlay";
import { useUser } from "../context/UserContext";
import { useHistory } from "../context/HistoryContext";
import { useMovies } from "../context/MoviesContext";
import { useLoading } from "../context/LoadingContext";
import { useCount } from "../context/CountContext";
import { useCurrentMovie } from "../context/CurrentContext";

import {
  fetchUserMovies,
  getRatingHistory,
  sendUserRating
} from "../utils/api";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-around",
    // alignItems: "center",
    color: "white"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.7)"
  },
  title: {
    display: "flex",
    justifyContent: "center"
  },
  card: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    maxHeight: "492px"
  },
  cardContainer: {
    position: "relative",
    height: "600px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden"
  },
  challenge: {
    display: "flex",
    justifyContent: "center"
  },
  help: {
    display: "flex",
    flexDirection: "row",
    flexGrow: "1",
    width: "1100px",
    margin: "0 auto",
    justifyContent: "center"
  },
  exitIcon: {
    fontSize: "2em",
    color: "rgb(119, 254, 57)",
    paddingLeft: "2.5%"
  },
  button: {
    fontSize: "1em",
    background: "transparent",
    border: "none",
    color: "white",
    outline: "none !important",
    letterSpacing: "2px",
    fontWeight: "bold"
  },
  error: {
    fontSize: "1.1em",
    color: theme.colors.primaryGreen,
    textAlign: "center"
  },
  icon: {
    transition: "display 0.5s ease",
    fontSize: "6em",
    zIndex: 9999,
    color: theme.colors.primaryGreen,
    margin: "0 auto"
  },
  loading: {
    textAlign: "center"
  }
}));

const RateMovies = ({ props }) => {
  const classes = useStyles(props);

  const [lastRatedType, setLastRatedType] = useState(false);
  const [lastMovieRated, setLastMovieRated] = useState({});
  const [showRatedType, setShowRatingType] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [isUndoVisible, setUndoVisible] = useState(false);
  const [error, setError] = useState(null);
  const [isOutOfMovies, setOutOfMovies] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [sampleMovies] = useState([
    {
      Title: "Harry Potter and the Sorcerer's Stone",
      "Release Year": 2001,
      PosterURL: "http://imgur.com/tdoXJzz.jpg",
      "ID HASH": 1,
      Rating: "PG-13",
      "Release Year": 2006,
      Length: "2 Hours 14 Minutes",
      synopsis: null,
    },
    {
      Length: "1 Hours 39 Minutes",
      MovieID: "ltnith",
      Rating: "PG-13",
      recRating: 0,
      synopsis: null,
      Title: "Iron Man",
      "Release Year": 2008,
      PosterURL: "http://imgur.com/w39vFxE.jpg",
      "ID HASH": 2
    },
    {
      Length: "1 Hours 5 Minutes",
      MovieID: "ltnith",
      Rating: "R",
      recRating: 0,
      "Release Year": 2008,
      synopsis: null,
      "ID HASH": 2,
      Title: "Taken 2",
      PosterURL: "http://imgur.com/c8rEauJ.jpg",
      "ID HASH": 4
    },
    {
      Title: "Moana",
      Length: "1 Hours 59 Minutes",
      Rating: "G",
      "Release Year": 2006,
      PosterURL: "https://i.imgur.com/LD1vc5A.jpg",
      "ID HASH": 5
    },
    {
      Title: "The Lion King",
      Length: "1 Hours 27 Minutes",
      Rating: "G",
      recRating: 0,
      "Release Year": 1994,
      PosterURL: "http://imgur.com/6zbzC8m.jpg",
      "ID HASH": 3
    }
  ]);
  const [ratedMoviesList, setRatedMoviesList] = useState([]);
  // CONTEXT
  const { history, setCurrentHistory } = useHistory();
  const { user, setCurrentUser } = useUser();
  const { movies, setCurrentMovies } = useMovies();
  const { currentMovie, setCurrentMovie } = useCurrentMovie();
  const { count, setCurrentCount } = useCount();
  const { loading, setLoading } = useLoading();

  // set keyboard functionality
  useEffect(() => {
    const listener = event => {
      if (event.code === "Space") {
        event.preventDefault();
        handleRateMovie("loved");
      }
      if (event.code === "ArrowUp") {
        event.preventDefault();
        handleRateMovie("okay");
      }
      if (event.code === "ArrowLeft") {
        event.preventDefault();
        handleRateMovie("disliked");
      }
      if (event.code === "ArrowRight") {
        event.preventDefault();
        handleRateMovie("liked");
      }
      if (event.code === "ArrowDown") {
        event.preventDefault();
        handleRateMovie("unseen");
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });

  useEffect(() => {
    if (!user) {
      setCurrentMovies(sampleMovies);
      setCurrentMovie(sampleMovies[4])
    } 
  }, []);

  const checkForMoreMovies = async () => {
    setLoading(true)
    setIsFetching(true)
    console.log("Low on movies, fetching more!");
    const latest = await fetchUserMovies(user.uid);
    if (latest){
      console.log("NEW:", latest.movies)
      setIsFetching(false)
      setLoading(false)
      const unique = uniqBy(latest.movies, 'ID HASH') 
      // var unique = latest.movies.filter(o => !movies.find(o2 => o['ID HASH'] === o2['ID HASH']))
      // console.log("NEW MOVIES", latest.movies);
      // console.log("MERGED MOVIES", unique);
      setCurrentMovie(unique[unique.length - 1]);
      setCurrentMovies(unique)
    }
  };

  const handleRatingAPI = async type => {
    if (!user && movies.length > 0) {
      setCurrentCount(count => count + 1);
      const last = movies.pop();
      setUndoVisible(true);
      setLastMovieRated(last);
      setCurrentMovie(movies[movies.length - 1]);
    }
    if (user && movies.length > 0) {
      setCurrentCount(count => count + 1);
      setUndoVisible(true);
      const last = movies.pop();
      console.log(movies)
      setLastMovieRated(last);
      sendUserRating(type, user.uid, last);
      setRatedMoviesList([...ratedMoviesList, last])
      setCurrentMovie(movies[movies.length - 1]);
      // setAllMoviesList(allMoviesList);
    }
  };

  const sendMovieRating = type => {
    switch (type) {
      case "unseen":
        handleRatingAPI(1);
        break;
      case "disliked":
        handleRatingAPI(2);
        break;
      case "okay":
        handleRatingAPI(3);
        break;
      case "liked":
        handleRatingAPI(4);
        break;
      case "loved":
        handleRatingAPI(5);
        break;
      case "undo":
        // handle case to remove logged in movie from rating (set to unrated)
        setCurrentCount(count => count - 1);
        setUndoVisible(false);
        setCurrentMovies([...movies, lastMovieRated])
        setCurrentMovie(lastMovieRated);
        break;
      default:
        console.log("Error: Check the rating");
    }
  };

  const handleRateMovie = type => {
    // SHOWS ICON WHEN RATING A MOVIE
    triggerTypeIcons(type);
    if (user && !isFetching && movies && movies.length === 1) {
      // if movies are low, fetch more
      checkForMoreMovies()
    }
    if (movies.length >= 1) {
      sendMovieRating(type);
    }
  };

  const triggerTypeIcons = type => {
    setLastRatedType(type);
    setShowRatingType(true);
    setTimeout(() => {
      setShowRatingType(false);
    }, 500);
  };

  let info = {
    currentMovie,
    showRatedType,
    lastRatedType,
    movies,
    error,
    isUndoVisible,
    handleRateMovie,
  };

  // logged in, loading API, first time fetching
  if (user && loading && movies && movies.length === 0){
    return <MoviesLoading />;
  }

  // no user, sample movies
  if (!user && movies.length > 0) {
    return <MoviesRate {...info} />;
  // no user, out of sample movies
  } else if (!user && movies.length < 1) {
    return <MoviesLogin />;
  // logged in, movies
  } else if (user && movies && movies.length > 0) {
    return <MoviesRate {...info} />;
  // logged in, not loading, movies out
  } else if (user && !loading && movies && movies.length < 1){
    return <MoviesEmpty />;
  } else {
    return null;
  }
};

export default RateMovies;
