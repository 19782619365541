import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Collapse
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "30px 0", 
    color: "white",
    borderTop: "1px solid black",
  },
  item: {
    marginLeft: "5px",
    color: "Silver",
    "&:hover": {
      color: "green"
    }
  },
  party: {
    marginLeft: "0",
    paddingLeft: "5px",
    color: "Silver",
    borderBottom: "1px solid gray"
  },
  titleItem: {
    margin: "15px 0 0 0",
    padding: "0 15px 0 5px"
  },
  title: {
    color: "rgb(119, 254, 57, 1)"
  },
  itemText: {
    letterSpacing: "1px",
    color: "white"
  },
  arrow: {
    transform: "rotate(90deg)"
  }
}));

export default function OverlayPoster(props) {
  const classes = useStyles(props);
  const [open, setOpen] = useState({});
  const [katchPartyList, setkatchPartyList] = useState([]);

  useEffect(() => {
    setkatchPartyList(props.list);
  });

  const { closeOverlay } = props;

  const handleClick = title => {
    setOpen(prevState => ({...prevState,  [title]: !prevState[title] }));
    console.log(open)
    // setOpen(prevState => (console.log(prevState, title)));
  };

  return (
    <>
      <div className={classes.root}>
        <Box>
          <Typography>Saved KatchParties</Typography>
          <List>
            <Divider variant="inset" component="li" />
            {katchPartyList.map((party, index) => {
              return (
                <>
                  <ListItem
                    key={party.title}
                    className={classes.party}
                    onClick={() => handleClick(party.title)}
                  >
                    <ListItemText
                      primary={party.title}
                      className={classes.itemText}
                    />
                    {open[party.title] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open[party.title]} timeout="auto" unmountOnExit>
                    {party.names.map((person, index) => (
                      <ListItem key={person} className={classes.item}>
                        <ListItemText
                          primary={person}
                          className={classes.itemText}
                        />
                      </ListItem>
                    ))}
                  </Collapse>
                </>
              );
            })}
          </List>
          <Divider light />
        </Box>
      </div>
    </>
  );
}
