import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";

import PosterRecommendation from "../components/PosterRecommendation";
import RecRatingButtons from "../components/RecRatingButtons";
import { Typography, Grid } from "@material-ui/core";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { getRecommendations } from "../utils/api";
import { useUser } from "../context/UserContext";
import { useRecommendations } from "../context/RecommendationsContext";


const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "auto"
    },
    maxWidth: "1100px",
    // height: "65vh",
    display: "flex",
    margin: "0 auto",
    marginBottom: "60px",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white"
  },
  title: {
    display: "flex",
    justifyContent: "center"
  },
  card: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    padding: "0 15px 0 15px"
    // maxHeight: "492px"
  },
  new: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
    padding: 0
  },
  exitIconGray: {
    fontSize: "2em",
    color: "gray",
    paddingLeft: "2.5%"
  },
  exitIconGreen: {
    fontSize: "2em",
    color: "rgb(119, 254, 57)",
    paddingLeft: "2.5%"
  },
  button: {
    fontSize: "1em",
    background: "transparent",
    border: "none",
    color: "white",
    outline: "none !important",
    letterSpacing: "2px",
    cursor: "pointer",
  },
  goodText: {
    textAlign: "center",
    fontFamily: "Samsung Sharp Sans Medium",
    fontSize: "0.6em",
    marginTop: "15px", 
    letterSpacing: "1.5px",
    marginLeft: "-120px",
    width: "200px"
  }
}));



export default function RateMovies(props) {
  const classes = useStyles(props);
  
  const { user, setCurrentUser } = useUser();
  const { recommendations, setCurrentRecommendations } = useRecommendations();

  const [ previousRecs, setPreviousRecs ] = useState([]);
  const [ forwardRecs, setForwardRecs ] = useState([]);
  const [ recs, setRecs ] = useState([])
	
  const baseRecs = [
    {
      title: "Capote",
      releaseYear: "2005",
      trailer: "https://www.youtube.com/watch?v=cjvBYqp8b6U",
      PosterURL: "https://imgur.com/iRvsCQI.jpg",
      Rating: "R",
      length: "2h 32m"
    },
    {
      title: "Night at the Museum: Secret of the Tomb",
      releaseYear: "2014",
      trailer: "https://www.youtube.com/watch?v=KMKk7Dn__-Y",
      PosterURL: "https://firebasestorage.googleapis.com/v0/b/watchpartysite-4adf0.appspot.com/o/Movie_Posters%2Fnight_at_the_museum_secret_of_the_tomb.jpg?alt=media&token=2f87f9ce-9a28-4fe2-9bb9-47a7bc7c2449",
      Rating: "PG",
      length: "2h 32m"
    },
    {
      title: "The Princess Diaries",
      releaseYear: "2001",
      trailer: "https://www.youtube.com/watch?v=2CkcwPi20ms",
      PosterURL: "https://firebasestorage.googleapis.com/v0/b/watchpartysite-4adf0.appspot.com/o/Movie_Posters%2Fprincess_diaries_ver1.jpg?alt=media&token=08ae093c-9f22-4e23-a401-1e086266506d",
      Rating: "G",
      length: "2h 32m"
    }
  ]
  
  // sample for promise tracker
  // export default function App() {
  //   const [data, setData] = useState({});
  //   const { promiseInProgress } = usePromiseTracker();
  //   const loadData = async () => {
  //     const res = await fetch("https://api.agify.io/?name=michael");
  //     setData(await res.json());
  //   };
  //   useEffect(() => {
  //     trackPromise(loadData());
  //     return () => {};
  //   }, []);
  //   return <p>{promiseInProgress ? "loading" : data.name}</p>;
  // }

  const fetchRecommendations = async (count = 3) => {
    var users = user ? [user.uid] : [];
    if (recommendations.group.length > 0){
      users = recommendations.group;
    } else {
      users = user ? [user.uid] : [];
    }

    if (forwardRecs.length > 0){
      setRecs([forwardRecs[0], forwardRecs[1], forwardRecs[2]])
      setForwardRecs(forwardRecs.slice(3))
      setPreviousRecs([...recs, ...previousRecs])
    } else { 
      await getRecommendations(users).then(res => {
        if (res.movies && res.movies.rec.length > 1){
          setCurrentRecommendations({recs: res.movies.rec, group: users})
          setRecs(res.movies.rec)
        } else {
          setRecs(baseRecs);
        }
        setPreviousRecs([...recs, ...previousRecs]);
      });
    }
  };

  const showLastThreeRecs = () => {
    if (previousRecs.length > 0){
      setRecs([previousRecs[0], previousRecs[1], previousRecs[2]]);
      setPreviousRecs(previousRecs.slice(3))
      setForwardRecs([...recs, ...forwardRecs])
    }
  }

  const setRecRating = (rating) => {
    console.log("Received", rating)
  }

  // const getSingleRec = () => {
  //   console.log('single fetch')
  //   fetchRecommendations(1);
  // }

  useEffect(() => {
    if (recommendations && recommendations.recs.length > 0){
      setRecs(recommendations.recs);
    } else {
      fetchRecommendations();
    }
  }, [recommendations]);

  return (
    <Grid container className={classes.container}>
      {recs.map(movie => {
        // console.log(movie)
        return (
          <Grid item xs={12} lg={4} className={classes.card} key={movie.MovieID}>
            <PosterRecommendation key={movie.MovieID} movie={movie} setRecRating={setRecRating}/>
          </Grid>
        );
      })}
      <Typography className={classes.goodText}>
        From what you know about this movie, is this a good recommendation?
      </Typography>
      <Grid item xs={12} className={classes.new}>
        <button
          className={classes.button}
          variant="outline-primary"
          onClick={showLastThreeRecs}
        >
          <Typography
            component="span"
            style={{ fontFamily: "Samsung Sharp Sans Bold", width: "100%", float: "left" }}
          >
            Back
          </Typography>
          <Typography component="span" style={{ marginLeft: "10px", float: "left" }}>
            <ArrowBack className={previousRecs.length ? classes.exitIconGreen : classes.exitIconGray} />
          </Typography>
        </button>
        <button
          className={classes.button}
          variant="outline-primary"
          onClick={fetchRecommendations}
        >
          <Typography
            component="span"
            style={{ fontFamily: "Samsung Sharp Sans Bold", width: "100%", float: "right" }}
          >
            {forwardRecs.length > 0 ? "Next" : "See New Recs"}
          </Typography>
          <Typography component="span" style={{ marginRight: "10px", float: "right" }}>
            <ArrowForward className={classes.exitIconGreen} />
          </Typography>
        </button>
      </Grid>
    </Grid>
  );
}
