import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { pure } from "recompose";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
  Add,
  Favorite,
  CheckCircleRounded,
  VisibilityOff,
  ThumbUp,
  ThumbDown,
  Undo
} from "@material-ui/icons";

import {
  Tooltip,
  Zoom,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    background: "transparent",
    marginTop: "-1.5em",
    overflowX: "hidden",
    overflowY: "hidden",
    textAlign: "center",
    zIndex: "9999"
  },
  icon: {
    color: "white",
    padding: "5px",
    backgroundColor: theme.colors.primaryLightPurple,
    borderRadius: "50%",
    fontSize: "2em",
    margin: "0 5px",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)"
    }
  },
  liked: {},
  disliked: {
    // fontSize: "2.5em"
  },
  loved: {
    fontSize: "2.75em",
    paddingTop: "7px",
    "&:hover": {
      transform: "scale(1.05)"
    }
  },
  unseen: {
    // fontSize: "1.5em",
    position: "relative"
  },
  undo: {
    position: "relative",
    fontSize: "1.55em",
    marginRight: 0
  },
  okay: {
    marginLeft: "45px"
  }
}));

function SingleRatingBar(props) {
  const classes = useStyles(props);

  const { handleRateMovie, isUndoVisible } = props;

  const styles = {
    undoStyle: {
      visibility: isUndoVisible ? "visible" : "hidden"
    }
  }

  const { undoStyle } = styles;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      pointerEvents: "none",
      backgroundColor: "black",
      color: "white",
      fontSize: "1em",
      fontWeight: "bold"
    },
  }))(Tooltip);

  return (
    <div className={classes.container}>
      <LightTooltip title="It's Okay" TransitionComponent={Zoom} className={classes.tooltip} key="okay">
        <CheckCircleRounded
          className={classnames(classes.icon, classes.okay)}
          onClick={() => handleRateMovie("okay")}
        />
      </LightTooltip>
      <LightTooltip title="Didn't Like" TransitionComponent={Zoom} className={classes.tooltip} key="dislike">
        <ThumbDown
          className={classnames(classes.icon, classes.disliked)}
          onClick={() => handleRateMovie("disliked")}
        />
      </LightTooltip>
      <LightTooltip title="Loved It" TransitionComponent={Zoom} className={classes.tooltip} key="loved">
        <Favorite
          className={classnames(classes.icon, classes.loved)}
          onClick={() => handleRateMovie("loved")}
        />
      </LightTooltip>
      <LightTooltip title="Liked It" TransitionComponent={Zoom} className={classes.tooltip} key="liked">
        <ThumbUp
          className={classnames(classes.icon, classes.liked)}
          onClick={() => handleRateMovie("liked")}
        />
      </LightTooltip>
      <LightTooltip title="Haven't Seen" TransitionComponent={Zoom} className={classes.tooltip} key="unseen">
        <VisibilityOff
          className={classnames(classes.icon, classes.unseen)}
          onClick={() => handleRateMovie("unseen")}
        />
      </LightTooltip>
      <LightTooltip title="Undo" TransitionComponent={Zoom} className={classes.tooltip} key="1">
        <Undo
          className={classnames(classes.icon, classes.undo)}
          style={undoStyle}
          onClick={() => handleRateMovie("undo")}
        />
      </LightTooltip>
    </div>
  );
}

export default pure(SingleRatingBar);
