/* eslint-disable react/prop-types */
import { createMuiTheme } from "@material-ui/core/styles";

const Theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: "1.25em",
        fontFamily: "Samsung Sharp Sans Regular",
        letterSpacing: "1px",
        "&$focused": {
          color: "#77FE39"
        }
      }
    },
    "MuiRadio": {
      root: {
        color: "white !important"
      }
    },
    MuiList: {
      padding: {
        padding: "0 !important"
      }
    },
    MuiInput: {
      root: {
        "&$disabled": {
          color: "silver",
          // hover disabled field
          "&&&&:hover:before": {
            // borderBottomColor: "rgb(112, 128, 144, 1)",
            // opacity: "0.60"
          }
        }
      },
      input: {
        margin: "5px 0"
      },
      underline: {
        color: "silver",
        "&:hover": {
          borderBottomColor: "#77FE39"
        },
        "&:before": {
          borderBottomColor: "silver",
          borderBottomStyle: "solid !important"
        },
        "&:after": {
          borderBottomColor: "white"
        },
        "&&&&:hover:before": {
          borderBottom: "1px solid silver"
        }
      }
    }
  },
  typography: {
    fontFamily: [
      "Samsung Sharp Sans Regular",
      "Samsung Sharp Sans Bold",
      "Samsung Sharp Sans Medium",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif"
    ].join(",")
  },
  colors: {
    primaryGreen: "#77FE39",
    primaryDarkPurple: "#4B1684",
    primaryLightPurple: "#6C0BCB"
  }
});

export default Theme;
