import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import RatingSection from "../components/RatingSection";
import HistoryLoading from "../components/HistoryLoading";
// import MainOverlay from "../pages/MainOverlay";
import { getRatingHistory, getCompleteUser } from "../utils/api";
import { pure, onlyUpdateForKeys } from "recompose";

import { Backdrop, TextField, Grid, Tooltip, Zoom, Typography } from "@material-ui/core";
import { Close, Search, InfoOutlined } from "@material-ui/icons/";
import { useUser } from "../context/UserContext";
import { useHistory } from "../context/HistoryContext";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: "60px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
  search: {
    // height: "100px",
    display: "inline-flex"
  },
  text: {
    paddingLeft: "35px",
    paddingRight: "30px",
    color: "white",
    "&:before": {
      borderBottomColor: "#77FE39"
    },
    "&:after": {
      borderBottomColor: "#77FE39"
    },
    "&:focused": {
      borderBottomColor: "#77FE39"
    },
    "&:hover": {
      borderBottomColor: "#77FE39"
    }
  },
  textField: {
    color: "white",
    fontSize: "1.15em",
    marginLeft: "35px",
    marginRight: "35px",
    letterSpacing: "normal",
    fontWeight: "normal"
  },
  textOverride: {
    width: "100%"
  },
  searchIcon: {
    fontSize: "1.75em",
    color: "white",
    marginTop: "17px",
    marginRight: "-30px"
  },
  closeIcon: {
    fontSize: "1.75em",
    color: "white",
    marginTop: "20px",
    marginLeft: "-30px",
    "z-index": "10"
  },
  icon: {
    fontSize: "1.5em",
    color: "white",
    marginLeft: "auto"
  },
  inactivePage: {
    color: "darkgrey"
  },
  activePage: {
    color: "white"
  },
  title: {
    fontSize: "1.15em",
    color: "white"
  },
  divider: {
    backgroundColor: "white"
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)"
    // "overflow-x": "hidden"
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  tile: {
    width: "auto !important",
    marginTop: "15px"
  }
}));

const RatingHistory = ({props}) => {
  const classes = useStyles(props);
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [search, setSearchValue] = useState("");
  const [allMovies, setAllMovies] = useState();
  const [counts, setCounts] = useState({
    liked: 0,
    loved: 0,
    unseen: 0,
    disliked: 0,
    ok: 0,
    empty: true
  });
  const [results, setSearchResults] = useState({
    loved: {movies: []},
    liked: {movies: []},
    unseen: {movies: []},
    ok: {movies: []},
    disliked: {movies: []},
  });

  const [movies, setMovies] = useState({
    liked: {movies: [], start: "", after: ""},
    loved: {movies: [], start: "", after: ""},
    unseen: {movies: [], start: "", after: ""},
    ok: {movies: [], start: "", after: ""},
    disliked: {movies: [], start: "", after: ""}
  });

  const { user } = useUser();
  const { history, setCurrentHistory } = useHistory();

  let movieRatings = {
    liked: {movies: [], start: "", after: ""},
    loved: {movies: [], start: "", after: ""},
    unseen: {movies: [], start: "", after: ""},
    ok: {movies: [], start: "", after: ""},
    disliked: {movies: [], start: "", after: ""},
  }

  const fetchAllRatings = async () => {

    setLoading(true);
    const direction = "asc";
    const num = 20;
    const uid = user.uid

    const user_data = await getCompleteUser(uid);
    const countMap = countAllMovies(user_data);

    const liked = await getRatingHistory(uid, 'liked', direction, num);
    const loved = await getRatingHistory(uid, 'loved', direction, num);
    const unseen = await getRatingHistory(uid, 'unseen', direction, num);
    const ok = await getRatingHistory(uid, 'ok', direction, num);
    const disliked = await getRatingHistory(uid, 'disliked', direction, num);

    movieRatings.liked = {movies: liked.movies, start: liked.last_rating, latestCount: liked.count}
    movieRatings.loved = {movies: loved.movies, start: loved.last_rating, latestCount: loved.count}
    movieRatings.unseen = {movies: unseen.movies, start: unseen.last_rating, latestCount: unseen.count}
    movieRatings.ok = {movies: ok.movies, start: ok.last_rating, latestCount: ok.count}
    movieRatings.disliked = {movies: disliked.movies, start: disliked.last_rating, latestCount: disliked.count}
    movieRatings.countMap = countMap;

    setMovies(movieRatings);
    setCurrentHistory(movieRatings);
    setLoading(false);
  }

  const fetchRatings = async type => {
    const direction = "asc";
    const num = 20;
    const uid = user.uid

    const res = await getRatingHistory(uid, type, direction, num, movies[type].start);

    if (res.movies){
      setMovies({...movies, [type]: {movies:[...new Set([...movies[type].movies, ...res.movies])], start: res.last_rating, latestCount: res.count }})
      setCurrentHistory({...movies, [type]: {movies:[...new Set([...movies[type].movies, ...res.movies])], start: res.last_rating, latestCount: res.count }})
    }
  };

  useEffect(() => {
    if (user && Object.keys(history).length > 0) {
      setMovies(history);
      setCounts(history.countMap)
    } else {
      setMovies(movies);
    }
  }, [history]);

  useEffect(() => {
    if (user && Object.keys(history).length === 0) {
      fetchAllRatings()
    }
  }, [history]);

  useEffect(() => {
    setSearchResults(movies);
  }, [movies]);

  const handleOverlayClose = () => {
    setOverlayOpen(false);
  };

  const countAllMovies = (user_data) => {
    let count = user_data.user.data.rated_movies;
    let countMap = {
      liked: 0,
      loved: 0,
      unseen: 0,
      disliked: 0,
      ok: 0
    }
    for (const k in count){
      switch(count[k]){
        case 5:
          countMap.loved++
          break;
        case 4:
          countMap.liked++
          break;
        case 3:
          countMap.ok++
          break;
        case 2:
          countMap.disliked++
          break;
        case 1:
          countMap.unseen++
          break;
      }
    }
    setCounts(countMap);
    return countMap;
  };

  const clearSearch = () => {
    setSearchValue("");
    setMovies(history);
    setCounts(history.countMap)
    // setSearchResults(movies);
  };

  // TODO: use memoization to only search every few strokes
  // needs refactor 1) when data comes from API
  const filterSearchItems = e => {
    setSearchValue(e.target.value);
    if (e.target.value == ""){
      setSearchValue("");
      setMovies(history);
      setCounts(history.countMap)
    } else if (!counts.empty){
      let filteredResults = {
        liked: {movies: [], start: "", after: "", latestCount: 0},
        loved: {movies: [], start: "", after: "", latestCount: 0},
        unseen: {movies: [], start: "", after: "", latestCount: 0},
        ok: {movies: [], start: "", after: "", latestCount: 0},
        disliked: {movies: [], start: "", after: "", latestCount: 0},
      }

      const term = e.target.value.toLowerCase();

      let searchable = history;

      let filteredLoved = searchable.loved.movies.filter(item => {
        if (item.Title){
          let movieTitle = item.Title.toLowerCase();
          return movieTitle.search(term) !== -1;
        } else return false;
      });
      let filteredLiked = searchable.liked.movies.filter(item => {
        if (item.Title){
          let movieTitle = item.Title.toLowerCase();
          return movieTitle.search(term) !== -1;
        }
      });
      let filteredOk = searchable.ok.movies.filter(item => {
        if (item.Title){
          let movieTitle = item.Title.toLowerCase();
          return movieTitle.search(term) !== -1;
        }
      });
      let filteredUnseen = searchable.unseen.movies.filter(item => {
        if (item.Title){
          let movieTitle = item.Title.toLowerCase();
          return movieTitle.search(term) !== -1;
        }
      });
      let filteredDisliked = searchable.disliked.movies.filter(item => {
        if (item.Title){
          let movieTitle = item.Title.toLowerCase();
          return movieTitle.search(term) !== -1;
        }
      });

      filteredResults.loved.movies = filteredLoved
      filteredResults.liked.movies = filteredLiked
      filteredResults.ok.movies = filteredOk
      filteredResults.unseen.movies = filteredUnseen
      filteredResults.disliked.movies = filteredDisliked

      let ct = {
        liked: filteredLiked.length,
        loved: filteredLoved.length,
        unseen: filteredUnseen.length,
        disliked: filteredDisliked.length,
        ok: filteredOk.length
      }

      // setSearchResults(filteredResults);
      setCounts(ct)
      setMovies(filteredResults)
    } else return;
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      pointerEvents: "none",
      backgroundColor: "white",
      color: "black",
      fontSize: "1em",
      fontWeight: "bold"
    },
  }))(Tooltip);

  return (
    <Grid container className={classes.container}>
      {loading ? <HistoryLoading /> :
        <Grid item xs={4} className={classes.search}>
          <Search className={classes.searchIcon} />
          <TextField
            id="search-bar"
            label="Search"
            value={search}
            onChange={filterSearchItems}
            InputProps={{
              className: classes.text
            }}
            classes={{
              root: classes.textOverride
            }}
            InputLabelProps={{ className: classes.textField }}
          />
          {search.length > 0 && (
            <Close className={classes.closeIcon} onClick={clearSearch} />
          )}
        </Grid> 
      }
      <LightTooltip title={
        <>
          <Typography>To scroll: <br/></Typography>
          <Typography>Mouse:  Hold "shift" + scroll mouse <br/></Typography>
          <Typography>Trackpad:  Slide fingers </Typography>
        </>
      } TransitionComponent={Zoom} className={classes.tooltip} key="okay">
        <InfoOutlined className={classes.icon} />
      </LightTooltip>
      <RatingSection 
        movies={movies['loved'].movies} 
        count={counts.loved} text={"Loved"} 
        start={movies['loved'].start} latestCount={movies['loved'].latestCount} 
        fetchHistory={() => fetchRatings("loved")} 
        search/>
      <RatingSection 
        movies={movies['liked'].movies} 
        count={counts.liked} text={"Liked"} 
        start={movies['liked'].start} 
        latestCount={movies['liked'].latestCount} 
        fetchHistory={() => fetchRatings("liked")} 
        search/>
      <RatingSection 
        movies={movies['ok'].movies} 
        count={counts.ok} text={"Okay"} 
        start={movies['ok'].start} 
        latestCount={movies['ok'].latestCount} 
        fetchHistory={() => fetchRatings("ok")} 
        search/>
      <RatingSection
        movies={movies['disliked'].movies}
        count={counts.disliked}
        text={"Disliked"}
        start={movies['disliked'].start} 
        latestCount={movies['disliked'].latestCount} 
        fetchHistory={() => fetchRatings("disliked")}
        search
      />
      <RatingSection
        movies={movies['unseen'].movies}
        count={counts.unseen}
        text={"Haven't Seen"}
        start={movies['unseen'].start} 
        latestCount={movies['unseen'].latestCount} 
        fetchHistory={() => fetchRatings("unseen")}
        search
        last
      />
      <Backdrop
        open={isOverlayOpen}
        onClick={handleOverlayClose}
        className={classes.backdrop}
      >
        {/* <MainOverlay closeOverlay={handleOverlayClose} movie={selectedMovie} /> */}
      </Backdrop>
    </Grid>
  );
}

export default pure(RatingHistory)