import React, { useState, useEffect, useReducer } from "react";
import uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";
import { Redirect, NavLink, useHistory as routerHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { signup, fetchUserMovies, getRatingHistory } from "../utils/api";
import { useUser } from "../context/UserContext";
import { useMovies } from "../context/MoviesContext";
import { useLoading } from "../context/LoadingContext";
import { useCurrentMovie } from "../context/CurrentContext";
import { useCount } from "../context/CountContext";

import {
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  TextField,
  FormControl,
  Select,
  ListItemText,
  Input,
  InputLabel,
  MenuItem,
  Grid,
  CircularProgress
} from "@material-ui/core";

import { Edit, AddSharp } from "@material-ui/icons/";

const useStyles = makeStyles(theme => ({
  container: {
    // width: "55%",
  },
  formControl: {
    width: "100%"
  },
  form: {
    display: "flex",
    flex: "1",
    marginTop: "15px",
    marginBottom: "60px",
    alignItems: "center",
    justifyContent: "center"
  },
  fields: {
    width: "480px"
  },
  avatarContainer: {
    width: "auto",
    padding: "0px 30px"
  },
  textField: {
    color: "white",
    borderColor: "white",
    borderWidth: "10px",
    "&.focused": {
      color: "red"
    }
  },
  item: {
    borderTop: "1px solid purple"
  },
  selected: {
    backgroundColor: "lightgreen !important"
  },
  text: {
    fontSize: "1.2em",
    color: "white",
    borderColor: "#ced4da",
    marginBottom: "30px"
  },
  gender: {
    color: "white"
  }, 
  login: {
    fontSize: "0.75em",
    color: "white",
    backgroundColor: theme.colors.primaryDarkPurple,
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "47.5%",
    height: "60px",
    textTransform: "none",
    border: "2px solid white",
    "&:hover": {
      color: "white",
      backgroundColor: theme.colors.primaryDarkPurple,
      border: "2px solid white",
      borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: theme.colors.primaryGreen
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  signup: {
    fontSize: "0.75em",
    color: "white",
    backgroundColor: theme.colors.primaryDarkPurple,
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "220px",
    height: "60px",
    textTransform: "none",
    border: "2px solid white",
    "&:hover": {
      color: theme.colors.primaryGreen,
      backgroundColor: theme.colors.primaryDarkPurple,
      borderColor: "white",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: theme.colors.primaryGreen
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  error: {
    color: "red",
    marginTop: "20px"
  }
}));

export default function Signup(props) {
  const classes = useStyles(props);
  const [isSigningUp, setSigningUp] = useState(false);

  const [newUser, setUserSettings] = useState(
  //   {
  //   email: `${Math.floor(Math.random() * 1000)}@asd.com`,
  //   name: "Random Tester",
  //   password: `${Math.floor(Math.random() * 1000)}12345`,
  //   password2: "",
  //   services: ["Netflix", "HBOMax"],
  //   gender: "male",
  //   age: 55,
  //   zipcode: 12345
  // }
    {
      email: "",
      surname: "USER",
      name: "",
      phoneNumber: "",
      country: "USA",
      language: "EN",
      age: "",
      instagram: "@katchparty",
      password: "",
      password2: "",
      services: [],
  }
  );
  const [error, setError] = useState(null);
  const { user, setCurrentUser } = useUser();
  const { loading, setLoading } = useLoading();
  const { movies, setCurrentMovies } = useMovies();
  const { currentMovie, setCurrentMovie } = useCurrentMovie();
  const { count, setCurrentCount } = useCount();
  let h = routerHistory();

  const streamingServices = [
    "Netflix",
    "Amazon Prime Video",
    "Disney+",
    "HBOMax",
    "HBONow",
    "Hulu",
    "AppleTV+",
    "DirecTV",
    "Peacock",
    "YouTube Premium",
    "Cinemax",
    "Showtime",
    "Starz",
    "Crackle",
    "IMDb TV",
    "Criterion Channel",
    "fuboTV",
    "FX Now",
    "Hoopla",
    "Kanopy",
    "Pluto TV",
    "Sling TV",
    "Quibi"
  ];

  // useEffect(() => {
  //   setUserSettings({...newUser, password2: newUser.password})
  // },[])

  const handleChange = event => {
    setUserSettings({ ...newUser, [event.target.name]: event.target.value });
  };

  const handleSubmit = async event => {
    // console.log(newUser)

    if (!newUser.email){
      return setError("Please enter a valid email.")
    }
    if (!newUser.name){
      return setError("Please enter a valid name.")
    }
    if (!newUser.age){
      return setError("Please enter a valid age.")
    }
    if (!newUser.password){
      return setError("Please enter a valid password.")
    }
    if (newUser.password !== newUser.password2) {
      return setError("Passwords do not match.");
    } else {
      setError(null);
      event.preventDefault();
      setSigningUp(true);
      const response = await signup(newUser);
      if (response.error) {
        setError(response.error);
        setSigningUp(false);
      } else {
        setLoading(true);
        setCurrentUser({
          email: response.user.email,
          uid: response.user.localId,
          name: response.user.name,
          age: response.user.age,
          gender: response.user.gender,
          zipcode: response.user.zipcode,
        });
        setCurrentCount(0);
        setCurrentMovies([])
        h.push("/rate");
        const moviesResponse = await fetchUserMovies(response.user.localId);
        // const ratingResponse = await getRatingHistory(response.user.uid);
        
        if (moviesResponse){
          const unique = uniqBy(moviesResponse.movies, 'ID HASH')
          setCurrentMovies(unique);
          setCurrentMovie(unique[unique.length - 1])
          setLoading(false);

        }
      }
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <Grid container className={classes.container} justify={"center"}>
        <Grid item container className={classes.fields}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              id="name"
              name="name"
              fullWidth
              onChange={handleChange}
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              id="email"
              name="email"
              onChange={handleChange}
              fullWidth
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              id="password"
              name="password"
              type="password"
              fullWidth
              onChange={handleChange}
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Enter Password Again"
              id="password2"
              name="password2"
              type="password"
              fullWidth
              onChange={handleChange}
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Age"
              id="age"
              name="age"
              fullWidth
              onChange={handleChange}
              InputProps={{
                className: classes.text
              }}
              inputProps={{maxLength: 2}}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel
              component="legend"
              style={{
                color: "white",
                fontSize: "1.25rem",
                marginBottom: "20px"
              }}
            >
              Gender
            </FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="gender"
              onChange={handleChange}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
                style={{ color: "white" }}
              />
              <FormControlLabel
                value="male"
                id="gender"
                control={<Radio />}
                label="Male"
                style={{ color: "white" }}
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
                style={{ color: "white" }}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Zipcode"
              id="zipcode"
              name="zipcode"
              fullWidth
              onChange={handleChange}
              InputProps={{
                className: classes.text
              }}
              inputProps={{maxLength: 5}}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel style={{ color: "white" }}>
                Streaming Services
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                name="services"
                multiple
                value={newUser.services}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => selected.join(", ")}
              >
                {streamingServices.map(name => (
                  <MenuItem key={name} value={name} classes={{selected: classes.selected}} className={classes.item}>
                    <ListItemText primary={name} className={classes.streamingText} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.error}>
            {error}
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ display: "flex", paddingTop: "25px" }}
            justify="space-between"
          >
            <NavLink to="/login">
              <Button
                className={classes.signup}
                variant="contained"
                disableRipple
                disableelevation="true"
                type="button"
              >
                Have an account? Login!
              </Button>
            </NavLink>
            <Button
              className={classes.login}
              variant="contained"
              disableRipple
              disableelevation="true"
              type="button"
              disabled={isSigningUp ? true : false}
              onClick={handleSubmit}
            >
              {!isSigningUp ? "Sign Up!" : <CircularProgress className={classes.spinner} />}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
