import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Hidden } from "@material-ui/core";
import {
  Close,
  Check,
  Favorite,
  ThumbUp,
  ThumbDown,
  VisibilityOff,
  Visibility,
  Undo,
  ExpandMore,
  ExpandLess
} from "@material-ui/icons/";
import PosterRate from "../components/PosterRate";
import SingleRatingBar from "../components/SingleRatingBar";

import HelpBar from "../components/HelpBar";
// import MainOverlay from "./MainOverlay";
// import { useUser } from "../context/UserContext";
// import { useHistory } from "../context/HistoryContext";
import { useMovies } from "../context/MoviesContext";
// import { useCount } from "../context/CountContext";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-around",
    // alignItems: "center",
    color: "white"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.7)"
  },
  title: {
    display: "flex",
    justifyContent: "center"
  },
  card: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    maxHeight: "492px"
  },
  cardContainer: {
    position: "relative",
    height: "600px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden"
  },
  challenge: {
    display: "flex",
    justifyContent: "center"
  },
  help: {
    display: "flex",
    flexDirection: "row",
    flexGrow: "1",
    width: "1100px",
    margin: "0 auto",
    justifyContent: "center"
  },
  exitIcon: {
    fontSize: "2em",
    color: "rgb(119, 254, 57)",
    paddingLeft: "2.5%"
  },
  expandIcon: {
    margin: "auto",
    height: "20px", 
    color: theme.colors.primaryGreen,
  },
  button: {
    fontSize: "1em",
    background: "transparent",
    border: "none",
    color: "white",
    outline: "none !important",
    letterSpacing: "2px",
    fontWeight: "bold"
  },
  error: {
    fontSize: "1.1em",
    color: theme.colors.primaryGreen,
    textAlign: "center"
  },
  icon: {
    transition: "display 0.5s ease",
    fontSize: "6em",
    zIndex: 9999,
    color: theme.colors.primaryGreen,
    margin: "0 auto"
  },
  loading: {
    textAlign: "center"
  },
  shortcuts: {
    display: "flex",
    fontFamily: "Samsung Sharp Sans Medium", 
    color: "white", 
    height: "60px", 
    fontSize: "0.85em",
    border: "1px solid black",
    borderColor: theme.colors.primaryGreen,
    padding: "5px 5px 5px 10px",
    width: "175px",
    height: "20px",
    marginTop: "20px",
    cursor: "pointer",
  }
}));

const MoviesRate = (props) => {
  const { currentMovie, showRatedType, lastRatedType, error, handleRateMovie, isUndoVisible } = props;
  const classes = useStyles(props);

  const [showHelp, setShowHelp] = useState(false);

  // CONTEXT
  const { movies } = useMovies();

  // set keyboard functionality

  useEffect(() => {
    console.log("MOVIES TO RATE:", movies);
  }, []);

  const toggleHelpBar = () => {
    setShowHelp(!showHelp);
  }

  const renderRatingIcons = icon => {
    switch (icon) {
      case "watched":
        return <Visibility className={classes.icon} />;
      case "loved":
        return <Favorite className={classes.icon} />;
      case "liked":
        return <ThumbUp className={classes.icon} />;
      case "disliked":
        return <ThumbDown className={classes.icon} />;
      case "hated":
        return <Close className={classes.icon} />;
      case "okay":
        return (
          <Check
            className={classes.icon}
            style={{
              padding: "5px",
              backgroundColor: "#77FE39",
              borderRadius: "50%",
              color: "white"
            }}
          />
        );
      case "unseen":
        return <VisibilityOff className={classes.icon} />;
      case "undo":
        return <Undo className={classes.icon} />;
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          {/* <Grid item xs={12} md={4}> */}
          <Grid container className={classes.title}>
            <Typography
              align="center"
              style={{ width: "100%" }}
              component="span"
            >
              What did you think of
            </Typography>
            <Typography
              align="center"
              variant={"h6"}
              style={{ fontFamily: "Samsung Sharp Sans Medium" }}
              component="span"
            >
              {" "}
              {currentMovie && currentMovie.Title}
              {/* {currentMovie && currentMovie.Title}&nbsp;({currentMovie && currentMovie["Release Year"]})&nbsp; */}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.cardContainer}>
          {showRatedType && renderRatingIcons(lastRatedType)}
           {currentMovie && <PosterRate movie={currentMovie} key={currentMovie["ID HASH"]} />} 
          {/*movies && movies.map(movie => (
            <PosterRate movie={movie} key={movie["ID HASH"]} />
          ))*/}
        </Grid>
        <Grid item style={{ width: "100%" }}>
          {!error && (
            <SingleRatingBar
              handleRateMovie={handleRateMovie}
              isUndoVisible={isUndoVisible}
            />
          )}
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid container>
          <Grid item xs={12} className={classes.help} onClick={toggleHelpBar}>
            {!error && 
              <Typography 
                align="center"
                className={classes.shortcuts}
                onClick={toggleHelpBar}
                component="span">
                  Keyboard Shortcuts
                {!showHelp && <ExpandMore className={classes.expandIcon} />}
                {showHelp && <ExpandLess className={classes.expandIcon} />}
              </Typography>}
          </Grid>
          <Grid item xs={12} className={classes.help}>
            {!error && showHelp && <HelpBar />}
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={12} className={classes.help}>
        {error && (
          <Typography className={classes.error} component="span">
            {error}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default MoviesRate;
