import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Menu, MenuItem, Divider, Typography } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab/';
import { sendRecRating } from "../utils/api";
import { useUser } from "../context/UserContext";

import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
  container: {
    width: "280px"
  },
  login: {
    cursor: "pointer",
    textAlign: "center",
    color: theme.colors.primaryGreen
  },
  toggle: {
    display: "flex",
    fontSize: "0.75em",
    color: "white",
    backgroundColor: "gray",
    // border: "2px solid white !important",
    // borderColor: "#77FE39 !important",
    borderRadius: "10px !important",
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "80px",
    height: "35px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "gray",
      // border: "2px solid green",
      // borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.colors.primaryGreen,
      borderColor: theme.colors.primaryGreen,
      color: theme.colors.primaryDarkPurple,
    },
    "&:focus": {
      // boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  select: {
    color: "#4B1684 !important",
    backgroundColor: "#77FE39 !important"
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-around",
    width: "60%",
    margin: "20px auto 0 auto"
  }
}));

export default function RecRatingButtons(props) {
  const classes = useStyles(props);
  const { handleRecRating, movie } = props;

  const [showMenu, setShowMenu] = useState(false);
  const { user, setCurrentUser } = useUser();
  const [activeRec, setActiveRec] = React.useState(null);

  const scoreMap = {"yes": 1, "no": -1, null: 0}
  
  useEffect(() => {
    if (movie.recRating && movie.recRating !== 0){
      switch (movie.recRating) {
        case 1:
          setActiveRec("yes")
          break;
        case -1:
          setActiveRec("no")
          break;
        default:
          break;
      }
    } else {
      setActiveRec(null)
    }
  }, [props.movie])

  const handleRating = async (rating) => {
    
    // for testing / not logged in
    let ID;
    if (!user){
      ID = "1k0JEf64OwMMCYVIqD9BHpOeR3j1";
    } else {
      ID = user.uid;
    }
    props.setRecRating(rating);
    await sendRecRating(scoreMap[rating], ID, props.movie.MovieID);
  };

  const handleGroupToggle = (event, group) => {
    setActiveRec(group);
    handleRating(group)
  };

  return (
      <ToggleButtonGroup exclusive value={activeRec} onChange={handleGroupToggle} className={classes.buttonGroup}>
        <ToggleButton
          className={classes.toggle}
          variant="contained"
          value="no"
          disableRipple
          disableelevation="true"
          type="button"
          // onClick={handleRating}
          classes={{
            root: classes.override,
            selected: classes.select
          }}
        >
          NO
        </ToggleButton>
        <ToggleButton
          className={classes.toggle}
          variant="contained"
          value="yes"
          disableRipple
          disableelevation="true"
          type="button"
          // onClick={handleRating}
          classes={{
            root: classes.override,
            selected: classes.select
          }}
        >
          YES
        </ToggleButton>
      </ToggleButtonGroup>
  )
}
