import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    color: "white",
    padding: "6px 16px",
    justifyContent: "center",
    textAlign: "center"
  },
  text: {
    fontSize: "1.5em"
  }
}));

const MoviesEmpty = ({ props }) => {
  const classes = useStyles(props);

  // useEffect(() => {
  //   console.log("NO MOVIES LEFT TO RATE");
  // }, [movies]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.text} component={"span"}>
          Please log in or sign up to rate more movies.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MoviesEmpty;
