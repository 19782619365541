import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { pure, onlyUpdateForKeys } from "recompose";
import { Theaters } from "@material-ui/icons";

import { useLocation } from "react-router-dom";

import { Grid, Typography, CardMedia } from "@material-ui/core";

// import SingleRatingBar from "./SingleRatingBar";

const useStyles = makeStyles(theme => ({
  container: {
    width: "360px",
    position: "absolute"
    // height: "600px",
  },
  poster: {
    display: "flex",
    objectFit: "fill",
    flex: "1",
    height: "570px",
    maxWidth: "100%",
    margin: "0 auto",
    alignItems: "center"
    // "&:hover": {
    //   transform: "scale(1.008)"
    // }
  },
  backdrop: {
    background: "rgba(0,0,0,0.8)",
    position: "absolute",
    top: 0,
    color: "white",
    width: "100%",
    height: "100%",
    textAlign: "center"
  },
  backdropContainer: {
    position: "relative"
  },
  synopsis: {
    fontFamily: "Samsung Sharp Sans Regular",
    fontSize: "0.85em",
    padding: "10px",
    overflowY: "auto",
    height: "200px",
    marginBottom: "20px",
    "&::-webkit-scrollbar-thumb": {
      background: "#77FE39"
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.8)"
    },
    "&::-webkit-scrollbar": {
      width: "5px"
    }
  },
  details: {
    position: "absolute",
    display: "flex",
    zIndex: "400",
    height: "100%",
    top: 0,
    color: "white",
    padding: "25px",
    alignContent: "space-between",
    "&::-webkit-scrollbar-thumb": {
      background: "#77FE39"
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.8)"
    },
    "&::-webkit-scrollbar": {
      width: "5px"
    }
  },
  info: {
    width: "100%"
  },
  links: {
    width: "100%"
  },
  title: {
    fontFamily: "Samsung Sharp Sans Bold",
    letterSpacing: "1px"
  },
  rating: {
    width: "100%"
  },
  text: {
    fontSize: "0.85rem",
    textAlign: "center"
  },
  linkText: {
    fontFamily: "Samsung Sharp Sans Regular",
    padding: "10px",
    borderBottom: "2px solid white",
    padding: "5px 0"
  },
  changeRatingText: {
    display: "inline-block",
    padding: 0,
    marginTop: "10px",
    lineHeight: "10px",
    borderBottom: "1px solid white",
    fontSize: "0.75rem"
  },
  iconsContainer: {
    margin: "0 auto",
    position: "relative",
    color: "white",
    backgroundColor: "transparent"
  },
  spinner: {
    margin: "0 auto",
    height: "100px !important",
    width: "100px !important",
    color: theme.colors.primaryGreen,
  }
}));

function PosterRate(props) {
  const classes = useStyles(props);
  const [isActive, setActive] = useState(false);
  const { handleRateMovie, menuBarType, isUndoVisible, index } = props;
  const { PosterURL, Title, Rating, releaseYear, TrailerURL, Length, Logline } = props.movie;
  // Ask Jacob to change this to single word
  const Year = props.movie["Release Year"];
  // TODO: fix this ugly mess -- checks to display two types of poster
  const location = useLocation();
  const isLocationRate = location.pathname == "/rate" || location.pathname == "/";

  let title = Title ? Title : "";
  let runtime = Length;
  // let runtimeMatch = Length.match(/(\d+) Hours? (\d+) Minutes?/i);
  // if(runtimeMatch) {
  //   runtime = runtimeMatch[1] + "h " + runtimeMatch[2] + "m";
  // }

  return (
    <Grid className={classes.container} 
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <CardMedia
        component="img"
        className={classes.poster}
        image={PosterURL}
        title={title}
      />
      {isActive && (
          <div className={classes.backdrop}>
            <Grid item container xs={12} className={classes.details}>
              <Grid item className={classes.info}>
                <Grid item>
                  <Typography classes={{ body1: classes.title }}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography classes={{ body1: classes.text }}>
                    ({Year})
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.links}>
                <Grid item>
                  <Typography classes={{ body1: classes.synopsis }}>
                    {Logline}
                  </Typography>
                </Grid>
                {TrailerURL && 
                  <Grid item>
                    <a rel="noopener noreferrer" href={TrailerURL} target="_blank" style={{color: "white"}}>
                      <Theaters />
                      <Typography classes={{ body1: classes.linkText }}>
                        Watch Trailer
                      </Typography>
                    </a>
                  </Grid>
                }
              </Grid>
              <Grid item className={classes.rating}>
                <Grid item>
                  <Typography classes={{ body1: classes.text }}>
                    {Rating} &nbsp; | &nbsp; {runtime}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      {/* <div className={classes.poster}>
        <CircularProgress className={classes.spinner} />
      </div> */}
    </Grid>
  );
}

export default onlyUpdateForKeys(['Title'])(PosterRate);
