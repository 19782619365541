import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Dialog, TextField, Input, Button } from "@material-ui/core";
import PasswordModal from "../components/PasswordModal";
import { useHistory as routerHistory } from "react-router-dom";
import Poster from "../components/Poster";
import SingleRatingBar from "../components/SingleRatingBar";
import { getRecommendations } from "../utils/api";
import { useRecommendations } from "../context/RecommendationsContext";
import { useUser } from "../context/UserContext";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    width: "87.5%",
    flexDirection: "column",
    height: "620px",
    margin: "0 auto",
    marginBottom: "20px",
    border: "1px solid white",
    backgroundColor: "white",
    borderRadius: "20px",
    fontFamily: "Samsung Sharp Sans Medium",

  },
  title: {
    display: "flex",
    fontSize: "1.5em",
    fontWeight: "bold",
    color: "purple", 
    marginTop: "50px",
    fontFamily: "Samsung Sharp Sans Medium",
    letterSpacing: "1.5px"
  },
  header: {
    margin: "0 auto",
  },
  subtitle: {
    width: "720px",
    fontSize: "0.98em",
    margin: "20px auto",
    textAlign: "center",
    fontFamily: "Samsung Sharp Sans Medium",
    letterSpacing: "1px"
  },
  textField: {
    color: "white",
    borderColor: "white",
    borderWidth: "10px",
    "&.focused": {
      color: "red"
    }
  },
  text: {
    fontSize: "1.2em",
    color: "white",
    borderColor: "#ced4da",
    marginBottom: "30px"
  },
  titleContainer: {
    display: "flex",
    textAlign: "center",
    width: "100%",
    flexDirection: "column"
  },
  emailHeader: {
    display: "flex",
    alignItems: "start",
    marginLeft: "55px"
  },
  emailList: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginLeft: "40px"
  },
  emailInput: {
    color: "black", 
    marginLeft: "5px", 
    width: "90%"
  },
  emailTitle: {
    fontFamily: "Samsung Sharp Sans Medium",
  },
  disclaimer: {
    fontSize: "0.75em",
    fontFamily: "Samsung Sharp Sans Medium",
  },
  feedback: {
    color: theme.colors.primaryLightPurple,
    fontSize: "0.85em",
    fontFamily: "Samsung Sharp Sans Medium",

  },
  button: {
    marginTop: "20px"
  },
  launchKatch: {
    fontSize: "1em",
    color: theme.colors.primaryLightPurple,
    backgroundColor: theme.colors.primaryGreen,
    border: "2px solid white",
    fontFamily: "Samsung Sharp Sans Bold",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "260px",
    height: "60px",
    textTransform: "none",
    borderRadius: "20px",
    margin: "0 auto",
    "&:hover": {
      color: theme.colors.primaryLightPurple,
      backgroundColor: theme.colors.primaryGreen,
      border: "2px solid green",
      borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: "#005cbf"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  "@media screen and (min-width: 720px)": {
    text: {
      fontSize: "2.5vw"
    },
    emailList: {
      width: "400px"
    }
  },
  "@media only screen and (min-width: 960px)": {
    text: {
      fontSize: "2.5vw"
    }
  },
  "@media only screen and (max-width: 960px)": {
    text: {
      // fontSize: "1.05em"
    }
  }
}));

export default function KatchPartyGroup(props) {
  const classes = useStyles(props);
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState();
  const { recommendations, setCurrentRecommendations } = useRecommendations();
  const { user } = useUser();
  const [name, setName] = useState("Andrew");
  const h = routerHistory();

  const handleSubmitData = async (e) => {
    let emailArray = [];
    for (const item in emails) {
      emailArray.push(emails[item])
    }
    if (emailArray[0] !== currentEmail){
      emailArray.push(currentEmail)
    }
    // grab list of emails
    let recs = await getRecommendations(emailArray, 3);
    if (recs.movies !== null && recs.movies.rec.length > 0){
      setCurrentRecommendations({recs: recs.movies.rec, group: emailArray});
      h.push("/recommendations")
    } else {
      alert("Incorrect emails entered. Please try again")
    }
  };

  useEffect(() => {
    if (user){
      setCurrentEmail(user.email)
    } else {
      setCurrentEmail("test@test.com")
    }
  }, []);


  const handleChange = event => {
    setEmails({
      ...emails,
      "User00": currentEmail,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.titleContainer}>
        <Grid item xs={12} className={classes.header}>
          <Typography component="span" className={classes.title}>
            Welcome to KatchParty's Group Recommendation!
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.subtitle}>
          <Typography component="span" className={classes.subtitle}>
            Every participant needs to have a KatchParty Account and have rated at least 30 movies. The more movies the better ... obviously. 
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.emailHeader}>
          <Typography component="span" className={classes.emailTitle}>
            Please list the email address associated with up to 4 accounts
          </Typography>
        </Grid>
        <Grid item container xs={12} className={classes.emailList}>
          <Grid item xs={12}>
            <Typography component="span" className={classes.numbers}>
            1.
            </Typography>
            <Input
              id="email"
              name="User00"
              className={classes.emailInput}
              value={currentEmail}
              disabled
              onChange={handleChange}
              style={{color: "black", marginLeft: "5px", width: "90%"}}
              InputProps={{
                className: classes.text,
              }}
              // InputLabelProps={{ className: classes.textField }}
              />
          </Grid>
          <Grid item xs={12}>
            <Typography component="span" className={classes.numbers}>
            2.
            </Typography>
            <Input
              id="email"
              name="User01"
              className={classes.emailInput}
              onChange={handleChange}
              style={{color: "black", marginLeft: "5px"}}
              InputProps={{
                className: classes.text,
              }}
              // InputLabelProps={{ className: classes.textField }}
              />
          </Grid>
          <Grid item xs={12}>
            <Typography component="span" className={classes.numbers}>
            3.
            </Typography>
            <Input
              id="email"
              name="User02"
              className={classes.emailInput}
              onChange={handleChange}
              style={{color: "black", marginLeft: "5px"}}
              InputProps={{
                className: classes.text,
              }}
              // InputLabelProps={{ className: classes.textField }}
              />
          </Grid>
          <Grid item xs={12}>
            <Typography component="span" className={classes.numbers}>
            4.
            </Typography>
            <Input
              id="email"
              name="User03"
              className={classes.emailInput}
              onChange={handleChange}
              style={{color: "black", marginLeft: "5px"}}
              InputProps={{
                className: classes.text,
              }}
              // InputLabelProps={{ className: classes.textField }}
              />
          </Grid>
        </Grid>
      <Grid item container className={classes.button}>
        <Button
          className={classes.launchKatch}
          variant="contained"
          disableRipple
          disableelevation="true"
          type="button"
          onClick={handleSubmitData}
        >
          Launch KatchParty!
        </Button>
      </Grid>
      <Grid item xs={12} style={{marginTop: "20px", alignItems: "center", display: "flex", margin: "0 auto"}}>
        <Typography component="span" className={classes.disclaimer}>
        <span style={{fontWeight: "bold"}}>DISCLAIMER:</span> This is still very new so please take this with a grain of salt. Also, this is pre-genomic recommendations, so it will get much better later. 
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component="span" className={classes.feedback}>
          Please send any feedback to: beta@katch-media.com
        </Typography>
      </Grid>
      </Grid>

    </Grid>
  );
}
