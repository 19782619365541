import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";



const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    color: "white",
    padding: "6px 16px",
    justifyContent: "center",
    textAlign: "center"
  },
  text: {
    fontSize: "2em"
  }
}));

const MoviesEmpty = ({ props }) => {
  const classes = useStyles(props);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.text} component={"span"}>
          You have rated all available movies.
        </Typography>
        <Typography component={"div"}>
          Please check back another time for more!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MoviesEmpty;
