import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import "./App.css";
import Theme from "./Theme";
import Box from "@material-ui/core/Box";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

import Header from "./components/Header";
import Toolbar from "./components/Toolbar";
import Recommendations from "./pages/Recommendations";
import RateMovies from "./pages/RateMovies";
import KatchPartyGroup from "./pages/KatchPartyGroupRecs";
import MyAccount from "./pages/MyAccount";
import RatingHistory from "./pages/RatingHistory";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { AuthContext, useAuth } from "./context/AuthContext";
import { UserContext, useUser } from "./context/UserContext";
import { MoviesContext, useMovies } from "./context/MoviesContext";
import { HistoryContext, useHistory } from "./context/HistoryContext";
import { LoadingContext, useLoading } from "./context/LoadingContext";
import { CountContext, useCount } from "./context/CountContext";
import { CurrentMovieContext, useCurrentMovie } from "./context/CurrentContext";
import {
  RecommendationsContext,
  useRecommendations
} from "./context/RecommendationsContext";


// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const { authToken } = useAuth();
//   return (
//     <Route {...rest} render={props => (
//     authToken
//     ? <Component {...props} />
//     : <Redirect to={{ pathname: "/login", state: {referer: props.location }}} />
//     )} />
//   )
// }
// TODO: useReducer https://www.youtube.com/watch?v=wcRawY6aJaw

const App = () => {
  const existingToken = JSON.parse(localStorage.getItem("tokens"));
  const [authToken, setAuthToken] = useState(existingToken);
  const [user, setUser] = useState(null);
  const [count, setCount] = useState(0);
  const [movies, setMovies] = useState([]);
  const [currentMovie, setCurrentMovie] = useState([]);
  const [history, setHistory] = useState({});
  const [loading, setLoading] = useState(false);
  const [recommendations, setRecommendations] = useState({recs: [], group: []});
  // only re-renders if those values change
  // TODO: look into memo
  // const memoedValue = useMemo( () => ({authToken, setToken}), [authToken, setToken]

  const setToken = data => {
    localStorage.setItem("token", JSON.stringify(data));
    setAuthToken(data);
  };

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://w.appzi.io/w.js?token=MTEY0";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
        <Router>
          <ThemeProvider theme={Theme}>
            {/* // find a different solution for mobile  */}
            <Box
              style={{
                width: "85%",
                margin: "0 auto 50px auto",
                height: "100vh"
              }}
            >
              {/* use context for: Count, Movies, Ratings, User */}
              <UserContext.Provider value={{ user, setCurrentUser: setUser }}>
                <HistoryContext.Provider
                  value={{ history, setCurrentHistory: setHistory }}
                >
                  <MoviesContext.Provider
                    value={{ movies, setCurrentMovies: setMovies }}
                  >
                    <RecommendationsContext.Provider
                      value={{
                        recommendations,
                        setCurrentRecommendations: setRecommendations
                      }}
                    >
                      <CountContext.Provider
                        value={{ count, setCurrentCount: setCount }}
                      >
                        <LoadingContext.Provider
                          value={{ loading, setLoading }}
                        >
                          <CurrentMovieContext.Provider
                            value={{ currentMovie, setCurrentMovie }}
                          >
                            <Header />
                            <Route path="/login" component={Login} />
                            {/* {user && <Toolbar />} */}
                            <Route path="/" exact component={RateMovies} />
                            <Route path="/signup" component={Signup} />
                            <Route path="/rate" component={RateMovies} />
                            <Route path="/recs" component={KatchPartyGroup} />
                            <Route
                              path="/recommendations"
                              component={Recommendations}
                            />
                            <Route path="/history" component={RatingHistory} />
                            <Route path="/account" component={MyAccount} />
                          </CurrentMovieContext.Provider>
                        </LoadingContext.Provider>
                      </CountContext.Provider>
                    </RecommendationsContext.Provider>
                  </MoviesContext.Provider>
                </HistoryContext.Provider>
              </UserContext.Provider>
            </Box>
          </ThemeProvider>
        </Router>
      </AuthContext.Provider>
    </>
  );
};

export default App;
