import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory as routerHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Drawer,
  Grid,
  Typography,
  Toolbar,
  CardMedia,
  Button
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab/';

import DrawerMenu from "./DrawerMenu";
import ProfileMenu from "./ProfileMenu";

import { useUser } from "../context/UserContext";
import { useCount } from "../context/CountContext";
import { useRecommendations } from "../context/RecommendationsContext";

import logo from "../images/katch_logo_plus_dark.png";

const useStyles = makeStyles(theme => ({
  container: {
    background: "transparent",
    height: "120px"
  },
  logoContainer: {
    width: "180px",
    height: "auto"
  },
  katchLogo: {
    "-webkit-filter": "drop-shadow(2px 2px 2px #222)",
    filter: "drop-shadow(2px 2px 2px #222)"
  },
  toolbar: {
    flex: "1",
    display: "flex",
    justifyContent: "space-between"
  },
  profileContainer: {
    display: "flex",
    fontSize: "1em"
  },
  katch: {
    color: theme.colors.primaryGreen,
    background: "transparent",
    border: "none",
    outline: "none !important",
    letterSpacing: "1.5px",
    fontFamily: "Samsung Sharp Sans Medium"
  },
  rated: {
    marginLeft: "40px",
    color: "white",
    background: "transparent",
    border: "none",
    outline: "none !important",
    letterSpacing: "1.5px",
    fontWeight: "bold"
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "0 10px 0 15px",
    borderRadius: "50%"
  },
  login: {
    margin: "auto 0",
    cursor: "pointer",
    color: theme.colors.primaryGreen,
    fontSize: "1.5em"
  },
  expandIcon: {
    margin: "auto",
    color: theme.colors.primaryGreen
  },
  count: {
    position: "absolute",
    top: "80px",
    left: "72px"
  },
  override: {
    borderColor: "red"
  },
  rec: {
    fontSize: "0.65em",
    fontWeight: "bold",
    color: "white",
    backgroundColor: theme.colors.primaryLightPurple,
    border: "2px solid white !important",
    borderColor: "#77FE39 !important",
    borderRadius: "10px !important",
    fontFamily: "Samsung Sharp Sans Regular",
    outline: "none !important",
    letterSpacing: "1.5px",
    marginRight: "20px",
    // width: "47.5%",
    // height: "60px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: theme.colors.primaryLightPurple,
      border: "2px solid green",
      borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: theme.colors.primaryGreen,
      borderColor: theme.colors.primaryGreen,
      color: theme.colors.primaryDarkPurple,
    },
    "&:focus": {
      // boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  select: {
    color: "#4B1684 !important",
    backgroundColor: "#77FE39 !important"
  }
}));

export default function Header(props) {
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isGroupRecActive, setGroupRecActive] = useState(true);
  const [activeRec, setActiveRec] = React.useState(null);
  const { recommendations, setCurrentRecommendations } = useRecommendations();
  // const [ratingCount, setRatingCount] = useState(0);
  const { user } = useUser();
  const { count } = useCount();
  const h = routerHistory();
  const location = useLocation();
  let menu;

  const handleGroupToggle = (event, group) => {
    if (group == null && activeRec == 'solo'){
      return;
    } else if (activeRec == 'group' && group == null) {
      setActiveRec(group);
      h.push("/rate");
    } else if (activeRec == null && group == 'group') {
      setActiveRec(group);
      h.push("/recs");
    } else if (activeRec == 'history' && group == 'group') {
      setActiveRec(group);
      h.push("/recs");
    } else if (activeRec == 'solo' && group == 'group') {
      setActiveRec(group);
      h.push("/recs");
    } else if (activeRec == 'group' && group == 'solo') {
      if (recommendations.group.length > 0 && location.pathname == '/recommendations'){
        console.log('deleting current recs')
        setCurrentRecommendations({recs: [], group: []})
      }
      setActiveRec(group);
      h.push("/recommendations");
    } else if (group == 'history') {
      setActiveRec(group);
      h.push("/history");
    } else {
      setActiveRec(group);
      h.push("/recommendations");
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeRecs = () => {
    setActiveRec(null);
  }
  const clickLogin = () => {
    handleClose();
    h.push("/login");
  };
  
  const toRecs = () => {
	  h.push("/recommendations");
  }

  const toHistory = () => {
	  h.push("/history");
  }

  const clearRecButtons = () => {
    setCurrentRecommendations({recs: [], group: []})
    setActiveRec(null);
  }

  if (!user) {
    menu = (
      <Grid container className={classes.container}>
        <Grid item xs={12} style={{ padding: "6px 16px", display: "flex", justifyContent: "flex-end" }}>
          <Typography className={classes.login} onClick={clickLogin} component={"span"}>
            LOG IN
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    menu = (
      <>
        <div className={classes.profileContainer}>
          <Grid container style={{alignItems: "center"}}>
            <Grid item>
              <ToggleButtonGroup exclusive value={activeRec} onChange={handleGroupToggle}>
                <ToggleButton
                  className={classes.rec}
                  variant="contained"
                  value="history"
                  disableRipple
                  disableelevation="true"
                  type="button"
                  // onClick={showRec}
                  classes={{
                    root: classes.override,
                    selected: classes.select
                  }}
                >
                  Rating History
                </ToggleButton>
                <ToggleButton
                  className={classes.rec}
                  variant="contained"
                  value="group"
                  disableRipple
                  disableelevation="true"
                  type="button"
                  // onClick={showRec}
                  classes={{
                    root: classes.override,
                    selected: classes.select
                  }}
                >
                  Group Recommendations
                </ToggleButton>
                <ToggleButton
                  className={classes.rec}
                  variant="contained"
                  value="solo"
                  disableRipple
                  disableelevation="true"
                  type="button"
                  // onClick={showRec}
                  classes={{
                    root: classes.override,
                    selected: classes.select
                  }}
                >
                  Recommendations
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          {/* <button className={classes.katch} onClick={toggleDrawer}>
            MY KATCHPARTY
            </button> */}
		   {/* <Typography onClick={toRecs} component={"span"}>
            Recommendations
          </Typography> */}
          <div onClick={handleClick} style={{ display: "flex", cursor: "pointer" }}>
            <Avatar className={classes.avatar} alt="Hello">
              {user && user.email.toUpperCase().charAt(0)}
              {/* {user && user.email.toUpperCase().charAt(0)} */}
            </Avatar>
            <ExpandMore className={classes.expandIcon} />
          </div>
        </div>

        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
          <DrawerMenu toggleDrawer={toggleDrawer} />
        </Drawer>
        <ProfileMenu handleClose={handleClose} anchorEl={anchorEl} clearRecs={clearRecButtons} />
      </>
    );
  }

  return (
    <AppBar position="static" className={classes.container} elevation={0}>
      <Toolbar className={classes.toolbar} disableGutters>
        <div className={classes.count}>
        {count !== 0 && count.toString().padStart(4, "0")}
        </div>
        <Link to="/rate">
          <div className={classes.logoContainer} onClick={closeRecs}>
            <CardMedia
              src={logo}
              alt="Katch Logo"
              component="img"
              className={classes.katchLogo}
            />
          </div>
        </Link>
        {menu}
      </Toolbar>
    </AppBar>
  );
}
