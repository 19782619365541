import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { pure } from "recompose";
import { Grid, Typography, CardMedia } from "@material-ui/core";

const useStyles = makeStyles(props => ({
  container: {
    // width: "100%",
    height: "100%"
  },
  posterContainer: {
    height: "100%"
  },
  poster: {
    display: "flex",
    objectFit: "fill",
    flex: "1",
    maxHeight: "570px",
    maxWidth: "100%",
    margin: "0 auto"
    // "&:hover": {
    //   transform: "scale(1.008)"
    // }
  },
  backdrop: {
    background: "rgba(0,0,0,0.8)",
    position: "absolute",
    top: 0,
    color: "white",
    width: "100%",
    height: "100%",
    textAlign: "center"
  },
  backdropContainer: {
    position: "relative"
  },
  details: {
    position: "absolute",
    display: "flex",
    zIndex: "400",
    height: "100%",
    top: 0,
    color: "white",
    padding: "25px",
    alignContent: "space-between"
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "85%",
    width: "100%"
  },
  links: {
    width: "100%"
  },
  title: {
    marginBottom: "auto",
    fontFamily: "Samsung Sharp Sans Bold",
    letterSpacing: "1px"
  },
  text: {
    fontSize: "0.85rem"
  },
  rating: {
    fontSize: "0.75em"
  },
  linkText: {
    fontFamily: "Samsung Sharp Sans Bold",
    borderBottom: "2px solid white",
    padding: "5px 0"
  },
  changeRatingText: {
    display: "inline-block",
    padding: 0,
    marginTop: "10px",
    lineHeight: "10px",
    borderBottom: "1px solid white",
    fontSize: "0.75rem"
  },
  iconsContainer: {
    margin: "0 auto",
    position: "relative",
    color: "white",
    backgroundColor: "transparent"
  }
}));

function PosterHistory(props) {
  const classes = useStyles(props);
  const [isActive, setActive] = useState(false);
  const { handleRateMovie, menuBarType, isUndoVisible, index } = props;
  const { PosterURL, Title, Rating, releaseYear, trailer, Length } = props.movie;
  // Ask Jacob to change this to single word
  // const Year = props.movie["Release Year"];
  // TODO: fix this ugly mess -- checks to display two types of poster
  const location = useLocation();
  const isLocationRate =
    location.pathname == "/rate" || location.pathname == "/";

  return (
    <Grid
      className={classes.container}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <Grid container className={classes.posterContainer}>
        <CardMedia
          component="img"
          className={classes.poster}
          image={PosterURL}
          title={Title}
        />
        {isActive && (
          <div className={classes.backdrop}>
            <Grid item container xs={12} className={classes.details}>
              <Grid item className={classes.info}>
                <Grid item>
                  <Typography classes={{ body1: classes.title }}>
                    {Title}
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <Typography classes={{ body1: classes.text }}>
                    ({releaseYear})
                  </Typography>
                </Grid> */}
                <Grid item>
                  <Typography classes={{ body1: classes.rating }}>
                    {Rating} | {Length}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.links}>
                {/* <Grid item>
                  <Typography classes={{ body1: classes.linkText }}>
                    View on IMDB
                  </Typography>
                </Grid> */}
                <Grid item>
                <a rel="noopener noreferrer" href={trailer} target="_blank" style={{color: "white"}}>
                  <Typography classes={{ body1: classes.linkText }}>
                    Watch Trailer
                  </Typography>
                  </a>
                </Grid>
                {/* <Grid item style={{ marginTop: "10px" }}>
                  <Typography
                    component="span"
                    classes={{ body1: classes.changeRatingText }}
                  >
                    Change My Rating
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default pure(PosterHistory);
