import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import axios from "axios";


var firebaseConfig="";

var project = "watchpartysite-4adf0"; //replaced by set_project.sh

var apiRoot = "";
var userApi = ""

if(project=="watchpartysite-4adf0") {
	firebaseConfig = {
	  apiKey: "AIzaSyC97i-0okZvyXzXdX1Wn7-vnBL4JbvB0iQ",
	  authDomain: "watchpartysite-4adf0.firebaseapp.com",
	  databaseURL: "https://watchpartysite-4adf0.firebaseio.com",
	  projectId: "watchpartysite-4adf0",
	  storageBucket: "watchpartysite-4adf0.appspot.com",
	  messagingSenderId: "792387790282",
	  appId: "1:792387790282:web:cdc5421406a21d08301b33"
	};
	apiRoot = "https://watchpartysite-4adf0.appspot.com"
	userApi = 'https://api.katchplus.com'
}
else if(project=="watch-party-test-71b4e") {
	firebaseConfig = {
	  apiKey: "AIzaSyBJM-U6ZGMF2iItNbXbDrds043_LM4htd0",
	  authDomain: "watch-party-test-71b4e.firebaseapp.com",
	  databaseURL: "https://watch-party-test-71b4e.firebaseio.com",
	  projectId: "watch-party-test-71b4e",
	  storageBucket: "watch-party-test-71b4e.appspot.com",
	  messagingSenderId: "945186679352",
	  appId: "1:945186679352:web:d2321f92852af8e9be54cd"
	};
	apiRoot = "https://watch-party-test-71b4e.appspot.com";
	userApi = 'https://api-dev.katchplus.com'
}
else if(project=="local") {
	firebaseConfig = {
	  apiKey: "AIzaSyBJM-U6ZGMF2iItNbXbDrds043_LM4htd0",
	  authDomain: "watch-party-test-71b4e.firebaseapp.com",
	  databaseURL: "https://watch-party-test-71b4e.firebaseio.com",
	  projectId: "watch-party-test-71b4e",
	  storageBucket: "watch-party-test-71b4e.appspot.com",
	  messagingSenderId: "945186679352",
	  appId: "1:945186679352:web:d2321f92852af8e9be54cd"
	};
	apiRoot = "https://localhost:8080";
	userApi = 'https://api-dev.katchplus.com'
}

firebase.initializeApp(firebaseConfig);


const login = async user => {
  console.log("Loggin in with:", user);
  const url = `${userApi}/users/signin`;
  let response = {
    error: null,
    user: null
  };

  await axios
    .post(url, {
      email: user.email,
      password: user.password
    })
    .then(function (res) {
      console.log('user', res.data.user)
      response.user = res.data.user;
    })
    .catch(function (error) {
      console.log('error', error)
      response.error = error.toString();
    });

  return response;
};

const signup = async data => {
  console.log("Signing up with", data);
  const url = `${userApi}/users/signup`;
  let response = {
    error: null,
    user: null
  };

  await axios
    .post(url, {
      email: data.email,
      password: data.password,
      forename: data.name,
      surname: data.surname,
      "phoneNumber": data.phoneNumber,
      "country": data.country,
      "language": data.language,
      "age": data.age,
      "instagram":data.instagram
    })
    .then(function (res) {
      console.log('user', res.data)
      response.user = res.data.user;
    })
    .catch(function (error) {
      console.log('error', error)
      response.error = error.toString();
    });

    await mapUser(response.user.localId)

    response.user.name = data.name;
    response.user.zipcode = data.zipcode;
    response.user.age = data.age;
    response.user.gender = data.gender;
    response.user.services = data.services;

  return response;
};

const logout = async () => {
  let response = {
    error: null,
    user: null
  };
  await firebase
    .auth()
    .signOut()
    .then(function (res) {
      console.log("Logged out", res);
    })
    .catch(function (error) {
      console.log(error);
      response.error = error.message;
    });
  return response;
};


const getCompleteUser = async uid => {
  const url = `${apiRoot}/users/${uid}`;
  let response = {
    error: null,
    user: null
  };
  await axios
    .get(url)
    .then(function (res) {
      console.log('user', res.data)
      response.user = res.data;
    })
    .catch(function (error) {
      response.error = error;
    });

  return response;
};

const updateUserAccount = async (user, data) => {
  await axios.post(`${apiRoot}/apiuserupdate`, {
    UserID: user,
    email: data.email,
    name: data.name ? data.name : "",
    zipcode: data.zipcode ? data.zipcode : "",
    age: data.age ? data.age : "",
    gender: data.gender ? data.gender : "",
    services: data.services || []
  });
  const updatedUser = await axios.get(
    `${apiRoot}/users/${user}`
  );
  console.log("User Updated: ", updatedUser.data.data);
  return updatedUser.data.data;
};

const updateUserImage = async data => {
  var user = firebase.auth().currentUser;
  console.log("usr", user);
  console.log("incoming", data);
  await user
    .updateProfile({
      photoURL: data.url
    })
    .then(() => {
      console.log("Updated Image");
    })
    .catch(error => {
      console.log("Error updating image:", error);
    });
};

const sendPasswordResetEmail = async email => {
  let response = "";
  await firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      response = "Password reset email sent.";
    })
    .catch(function (error) {
      console.log("Error:", error);
      if (error.code === "auth/invalid-email") {
        response = "Enter valid email and click Forgot Password";
      } else {
        response = error.message;
      }
    });
  return response;
};

const fetchUserMovies = async uid => {
  console.log("Fetching movies in API...");
  const url = `${apiRoot}/movies/${uid}`;
  let response = {
    error: null,
    movies: null
  };
  await axios
    .get(url)
    .then(res => {
      response.movies = res.data;
      console.log("Movies returned from API: ", response.movies);
    })
    .catch(error => {
      response.error = error;
    });

  return response;
};

const sendUserRating = async (rating, user, movie) => {
  const hash = movie["ID HASH"];
  console.log("Rating:", rating, user, hash);
  const url = `${apiRoot}/movies`;
  let response = {
    error: null,
    rating: null
  };

  await axios
    .post(url, {
      MovieID: hash,
      RatingValue: rating,
      UserID: user
    })
    .then(res => {
      console.log("Rating response:", res.data);
      response.rating = res.data;
    })
    .catch(error => {
      response.error = error;
      console.log("Rating error:", error);
    });

  return response;
};

const sendRecRating = async (rating, user, movie) => {
  const url = `${apiRoot}/rec`;
  let response = {
    error: null,
    rating: null
  };

  await axios
    .post(url, {
      MovieID: movie,
      RatingValue: rating,
      UserID: user
    })
    .then(res => {
      console.log("Rating response:", res.data);
      response.rating = res.data;
    })
    .catch(error => {
      response.error = error;
      console.log("Rating error:", error);
    });

  return response;
};

const getRecommendations = async (uids,num_movies=3) => {
  var url = `${apiRoot}/rec`;
    url += "?n_movies="+num_movies;
  if(uids[0]) url += "&User00="+uids[0]
  if(uids[1]) url += "&User01="+uids[1]
  if(uids[2]) url += "&User02="+uids[2]
  if(uids[3]) url += "&User03="+uids[3]
  let response = {
    error: null,
    movies: null
  };
  await axios
    .get(url)
    .then(function (res) {
      console.log("Recs: ", res.data);
      response.movies = res.data;
    })
    .catch(function (error) {
      console.log("Recs Error: ", error);
      response.error = error;
    });

  return response;
};

const getRatingHistory = async (uid, type, direction = "asc", num, start = "") => {
  const url = `${apiRoot}/history?UserID=${uid}&type=${type}&direction=${direction}&n_movies=${num}&start_after=${start}`;
  let response = {
    error: null,
    ratings: null,
    count: 0,
    type,
    last_rating: null,
    start_after: null
  };
  await axios
    .get(url)
    .then(function (res) {
      if (res.data && res.data.movies){
        var history = res.data.movies;
        response.movies = history;
        response.last_rating = res.data.last_rating ? res.data.last_rating : res.data.start_after; 
        response.start_after = res.data.start_after
        response.count = history.length;
      } else {
        response.movies = [];
        response.count = 0;
      }
      // const ratingArray = [];
      // for (const prop in rates) {
      //   ratingArray.push({ ...rates[prop] });
      // }
      // console.log("Rating History: ", ratingArray);
    })
    .catch(function (error) {
      console.log("Rating History Error: ", error);
      response.error = error;
    });
  return response;
};

const mapUser = async (uid) => {
  const response = await axios.get(`${apiRoot}/mapuser/${uid}`);
  if (response.data.isError) {
    console.log("CRITICAL ERROR: ", response.data.message);
  } else {
    console.log("New User Mapped: ", response.data.message);
  }
  return response;
};

const mapUsers = async () => {
  const response = await axios.get(`${apiRoot}/mapusers`);
  if (response.data.isError) {
    console.log("CRITICAL ERROR: ", response.data.message);
  } else {
    console.log("New User Mapped: ", response.data.message);
  }
  return response;
};

export {
  login,
  logout,
  signup,
  getCompleteUser,
  updateUserAccount,
  updateUserImage,
  sendPasswordResetEmail,
  fetchUserMovies,
  getRatingHistory,
  getRecommendations,
  sendUserRating,
  sendRecRating
};
