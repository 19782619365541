import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Link, NavLink, useHistory as routerHistory } from "react-router-dom";
import { Grid, Menu, MenuItem, Divider, Typography } from "@material-ui/core";
import { useUser } from "../context/UserContext";
import { useCount } from "../context/CountContext";
import { logout } from "../utils/api";
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
  container: {
    width: "280px"
  },
  item: {
    margin: "0 auto",
    position: "relative",
    color: "black",
    backgroundColor: "transparent",
    borderTop: "1px solid gray",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: theme.colors.primaryGreen,
      // border: "1px solid green",
      // borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none"
      // backgroundColor: "#4B1684",
      // borderColor: "#005cbf"
    },
    "&:focus": {
      // boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  login: {
    cursor: "pointer",
    textAlign: "center",
    color: theme.colors.primaryGreen
  }
}));

export default function ProfileMenu(props) {
  const classes = useStyles(props);

  const [error, setError] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const { user, setCurrentUser } = useUser();
  const { setCurrentCount } = useCount();

  useEffect(() => {
    user.isLoggedIn ? setShowMenu(true) : setShowMenu(false);
  }, [])

  let h = routerHistory();

  const handleLogout = async () => {
    props.clearRecs();
    const response = await logout();
    if (!response.error) {
      setCurrentCount(0)
      setCurrentUser(null);
      props.handleClose();
      return h.push("/login");
    } else {
      setError(response.error);
      alert("Failed to log out");
    }
  };

  const handleToolbarToggle = () => {
    setCurrentUser({ ...user, toolbar: !user.toolbar });
    props.handleClose();
  };

  const clickLogin = () => {
    props.handleClose();
    h.push("/login");
  };

  return (
    <Menu
      style={{ marginTop: "70px" }}
      id="simple-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      <Grid container className={classes.container}>
        {/* // TODO make this one component that maps over array of link names */}
        <Grid item xs={12} style={{ padding: "6px 16px 6px 16px" }}>
          {/* <Grid item>
          Last Movie Watched: <br></br>
          {user.lastMovieWatched}
        </Grid>
        <Grid item>Score: {user.score}</Grid>
        <Grid item>Total Hours: 23,200</Grid> */}
          <Grid item>
            Email: <span style={{ fontSize: "0.85em" }}>{user.email}</span>
          </Grid>
          <Grid item>
            Name: <span style={{ fontSize: "0.85em" }}>{user.name}</span>
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <MenuItem
            className={classes.item}
            onClick={props.handleClose}
            to="/account"
            component={Link}
          >
            My Account
          </MenuItem>
          <MenuItem className={classes.item} onClick={handleLogout}>
            Log Out
          </MenuItem>
          {/* <MenuItem className={classes.item} onClick={handleToolbarToggle}>
            {showMenu ? "Hide Menu" : "Show Menu"}
          </MenuItem> */}
        </Grid>
      </Grid>
    </Menu>
  );
}
