import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Typography,
  Grid,
  GridList,
  GridListTile,
} from "@material-ui/core";
import {
  Close,
  Check,
  ArrowForward,
  Favorite,
  ThumbUp,
  ThumbDown,
  VisibilityOff,
  Visibility,
  FiberManualRecord
} from "@material-ui/icons/";

import Poster from "./PosterHistory";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: "60px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
  search: {
    height: "100px",
    display: "inline-flex"
  },
  text: {
    paddingLeft: "35px",
    paddingRight: "30px",
    color: "white",
    "&:before": {
      borderBottomColor: "#77FE39"
    },
    "&:after": {
      borderBottomColor: "#77FE39"
    },
    "&:focused": {
      borderBottomColor: "#77FE39"
    },
    "&:hover": {
      borderBottomColor: "#77FE39"
    }
  },
  textField: {
    color: "white",
    fontSize: "1.15em",
    marginLeft: "35px",
    marginRight: "35px",
    letterSpacing: "normal",
    fontWeight: "normal"
  },
  textOverride: {
    width: "100%"
  },
  searchIcon: {
    fontSize: "1.75em",
    color: "white",
    marginTop: "17px",
    marginRight: "-30px"
  },
  closeIcon: {
    fontSize: "1.75em",
    color: "white",
    marginTop: "20px",
    marginLeft: "-30px",
    "z-index": "10"
  },
  icon: {
    fontSize: "1.5em",
    color: "white",
    marginRight: "10px",
    marginBottom: "-5px"
  },
  inactivePage: {
    color: "darkgrey"
  },
  activePage: {
    color: "white"
  },
  title: {
    fontSize: "1.15em",
    color: "white",
  },
  divider: {
    backgroundColor: "white"
  },
  gridList: {
    flexWrap: "nowrap",
    overflowX: "auto",
    // height: "340px",
    marginTop: "10px !important",
    "&::-webkit-scrollbar-track": {
      borderRadius: "20px",
      background: "rgba(0,0,0,0.3)"
    },
    "&::-webkit-scrollbar": {
      height: "20px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#77FE39",
      borderRadius: "20px"
    },
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  tile: {
    height: "100%", 
    width: "240px !important",
    padding: "5px !important"
    // marginTop: "15px"
  },
  next: {
    color: "#77FE39",
    width: "15% !important",
    fontSize: "1em",
  },
   main: {
     marginTop: props => props.first ? "0px" : "50px",
     marginBottom: props => props.last ? "50px" : "0px"
   }
}));

export default function RatingSection(props) {
  const classes = useStyles(props);

  const { count, movies, text, icon, fetchHistory, start, after, latestCount, search } = props;

  const renderIcons = (icon) => {
    switch(icon){
      case 'watched': 
        return <Visibility className={classes.icon} />
      case 'loved': 
        return <Favorite className={classes.icon} />
      case 'liked': 
        return <ThumbUp className={classes.icon} />
      case 'disliked': 
        return <ThumbDown className={classes.icon} />
      case 'hated': 
        return <Close className={classes.icon} />
      case 'okay': 
        return <Check className={classes.icon} />
      case 'unseen': 
        return <VisibilityOff className={classes.icon} />
      default:
        return null
    }
  }

  const displayTitle = (text == "Haven't Seen") ? 'unseen' : text.toLowerCase();

  const displayValue = (value) => {
    switch(value){
      case 5:
        return 'loved'
      case 4:
        return 'liked'
      case 3:
        return 'okay'
      case 2:
        return 'disliked'
      case 1:
        return 'unseen'
    }
  }
  

  return (
    <Grid item container xs={12} justify={"space-between"} className={classes.main}>
      <Grid item>
        {renderIcons(displayTitle)}
        <Typography component="span" className={classes.title}>
          {text} ({count})
        </Typography>
      </Grid>
      {/* <Grid item className={classes.arrows}>
        <FiberManualRecord className={classes.inactivePage} />
      </Grid> */}
      <Grid item xs={12}>
        <Divider className={classes.divider} />
        <GridList className={classes.gridList} cellHeight={"auto"} spacing={5}>
          {movies
            // .filter(movie => displayValue(movie.RatingValue[0]) == displayTitle)
            .map(movie => (
              <GridListTile
                key={movie.MovieID}
                className={classes.tile}
                // onClick={() => handleOverlayOpen(movie.id)}
              >
                <Poster movie={movie} menuBarType={"none"} style={{border: "1px solid red"}}/>
              </GridListTile>
            ))}
        {search !== "" && movies.length >= 20 && latestCount >= 19 && <ArrowForward onClick={fetchHistory} className={classes.next} />}
        </GridList>
      </Grid>
    </Grid>
  );
}
