import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Button,
  TextField,
  Typography,
  Grid
} from "@material-ui/core";

import {
  ControlPoint,
  Add,
  Favorite,
  CheckCircleRounded,
  VisibilityOff
} from "@material-ui/icons/";

import Poster from "../components/Poster";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: "1500",
    width: "500px",
    backgroundColor: theme.colors.primaryDarkPurple,
    padding: "40px"
  },

  textField: {
    color: "white",
    borderColor: "white",
    borderWidth: "10px",
    "&.focused": {
      color: "red"
    }
  },
  text: {
    fontSize: "1.2em",
    color: "white",
    borderColor: "#ced4da",
    marginBottom: "30px"
  },
  save: {
    fontSize: "0.75em",
    color: theme.colors.primaryDarkPurple,
    backgroundColor: theme.colors.primaryGreen,
    fontFamily: "Samsung Sharp Sans Bold",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "47.5%",
    height: "60px",
    textTransform: "none",
    "&:hover": {
      color: "black",
      backgroundColor: theme.colors.primaryGreen,
      border: "1px solid green",
      borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: "#005cbf"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  cancel: {
    fontSize: "0.75em",
    color: "white",
    backgroundColor: theme.colors.primaryDarkPurple,
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "47.5%",
    height: "60px",
    textTransform: "none",
    border: "2px solid white",
    "&:hover": {
      color: theme.colors.primaryGreen,
      backgroundColor: theme.colors.primaryDarkPurple,
      border: "2px solid white",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: theme.colors.primaryGreen
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684"
    }
  }
}));

export default function PasswordModal(props) {
  const classes = useStyles(props);

  const [error, setError] = useState(false);
  const [user, setUserSettings] = useState({
    name: "Jeff Bridges",
    email: "jimbobthedrummer@gmail.com",
    age: "21",
    gender: "male",
    city: "Seattle",
    password: "jello123"
  });

  useEffect(() => {
    // setMovie(props.movie);
  });

  const onSubmitData = () => {

    // handle PW 
      closeOverlay()
  };

  const { closeOverlay } = props;

  return (
      <Grid container className={classes.container} justify={"center"}>
        <Grid item container className={classes.fields}>
          {/* TODO ADD VALIDATION */}
          <Grid item xs={12}>
            <TextField
              label="Old Password"
              id="old-password"
              fullWidth
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="New Password"
              id="new-password"
              fullWidth
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirm Password"
              id="confirm-password"
              fullWidth
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          {error && <Grid item style={{color: "red", fontSize: "1.5em", marginBottom: "10px", marginTop: "-20px"}}>Passwords do not match.</Grid>}
          <Grid container item xs={12} justify={"space-between"}>
            <Button
              className={classes.cancel}
              variant="contained"
              disableRipple
              disableelevation="true"
              onClick={closeOverlay}
            >
              Cancel
            </Button>
            <Button
              className={classes.save}
              variant="contained"
              disableRipple
              disableelevation="true"
            >
              Save New Password
            </Button>
          </Grid>
        </Grid>
      </Grid>
  );
}
