import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

import { Grid, Typography, CardMedia } from "@material-ui/core";

import GroupRatingBar from "./GroupRatingBar";
import SingleRatingBar from "./SingleRatingBar";

const useStyles = makeStyles(props => ({
  container: {
    // width: "100%",
    height: "100%",
  },
  poster: {
    display: "flex",
    objectFit: "fill",
    flex: "1",
    maxHeight: "570px",
    maxWidth: "100%",
    margin: "0 auto"
    // "&:hover": {
    //   transform: "scale(1.008)"
    // }
  },
  backdrop: {
    background: "rgba(0,0,0,0.8)",
    position: "absolute",
    top: 0,
    color: "white",
    width: "100%",
    height: "100%",
    textAlign: "center"
  },
  backdropContainer: {
    position: "relative"
  },
  details: {
    position: "absolute",
    display: "flex",
    zIndex: "400",
    height: "100%",
    top: 0,
    color: "white",
    padding: "25px",
    alignContent: "space-between"
  },
  info: {
    width: "100%"
  },
  links: {
    width: "100%"
  },
  title: {
    fontFamily: "Samsung Sharp Sans Bold",
    letterSpacing: "1px"
  },
  text: {
    fontSize: "0.85rem"
  },
  linkText: {
    fontFamily: "Samsung Sharp Sans Bold",
    borderBottom: "2px solid white",
    padding: "5px 0"
  },
  changeRatingText: {
    display: "inline-block",
    padding: 0,
    marginTop: "10px",
    lineHeight: "10px",
    borderBottom: "1px solid white",
    fontSize: "0.75rem"
  },
  iconsContainer: {
    margin: "0 auto",
    position: "relative",
    color: "white",
    backgroundColor: "transparent"
  }
}));

export default function Poster(props) {
  const classes = useStyles(props);
  const { handleRateMovie, menuBarType, isUndoVisible } = props;
  const { PosterURL, Name, Length, RatingValue, Rating } = props.movie;
  // Ask Jacob to change this to single word
  // TODO: fix this ugly mess -- checks to display two types of poster
  const location = useLocation();

  const handleViewToggle = () => {};
  return (
    <Grid className={classes.container}>
      <CardMedia
        component="img"
        className={classes.poster}
        image={PosterURL}
        title={Name}
        onClick={handleViewToggle}
        // onMouseEnter={() => setActive(true)}
      />
      <Typography
        align="center"
        component="div"
        className={classes.iconsContainer}
      >
        {menuBarType === "group" && <GroupRatingBar />}
        {menuBarType === "rate" && (
          <SingleRatingBar
            handleRateMovie={handleRateMovie}
            isUndoVisible={isUndoVisible}
          />
        )}
        {menuBarType === "none" && ""}
      </Typography>
    </Grid>
  );
}
