import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    width: "400px",
    margin: "0 auto",
  },
  poster: {
    display: "flex",
    objectFit: "fill",
    flex: "1",
    height: "570px",
    maxWidth: "100%",
    margin: "0 auto",
    alignItems: "center",
    // border: "1px solid lightgray",
  },
  
  spinner: {
    margin: "0 auto",
    height: "100px !important",
    width: "100px !important",
    color: theme.colors.primaryGreen
  },
  spinnerPoster: {
    height: "100%"
  }
}));

function PosterSpinner(props) {
  const classes = useStyles(props);

  return (
    <Grid className={classes.container}>
      <div className={classes.poster}>
        <Grid container style={{height: "100%", flexDirection: "column", margin: "0 auto"}}>

        <Grid item style={{margin: "50% auto 0 auto"}}>
          <CircularProgress className={classes.spinner} />
        </Grid>
        <Grid item style={{textAlign: "center"}}>
          <Typography align="center" style={{ width: "100%" }} component="span">
            Loading movies...
          </Typography>
        </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

export default PosterSpinner;
