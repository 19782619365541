import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { pure } from "recompose";
import { Theaters, Toc } from "@material-ui/icons";

import errorImage from '../images/error.png';

import { Grid, Typography, CardMedia } from "@material-ui/core";

import GroupRatingBar from "./GroupRatingBar";
import RecRatingButtons from "../components/RecRatingButtons";

const useStyles = makeStyles(props => ({
  container: {
    width: "420px",
    height: "533px"
  },
  poster: {
    width: "100%",
    objectFit: "fill",
    height: "100%"
    // display: "flex",
    // objectFit: "contain",
    // flex: "1",
    // maxHeight: "570px",
    // maxWidth: "100%",
    // margin: "0 auto"
    // "&:hover": {
    //   transform: "scale(1.008)"
    // }
  },
  posterContainer: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  backdrop: {
    background: "rgba(0,0,0,0.8)",
    position: "absolute",
    top: 0,
    color: "white",
    width: "100%",
    height: "100%",
    textAlign: "center"
  },
  backdropContainer: {
    position: "relative"
  },
  details: {
    position: "absolute",
    display: "flex",
    zIndex: "400",
    height: "100%",
    color: "white",
    alignContent: "space-between",
    padding: "20px 0 20px 0",
  },
  info: {
    width: "100%"
  },
  links: {
    width: "100%"
  },
  rating: {
    width: "100%"
  },
  title: {
    fontFamily: "Samsung Sharp Sans Bold",
    letterSpacing: "1px",
    marginBottom: "10px"
  },
  text: {
    fontSize: "0.85rem",
  },
  linkText: {
    fontFamily: "Samsung Sharp Sans Regular",
    padding: "10px"
  },
  synopsis: {
    fontFamily: "Samsung Sharp Sans Regular",
    fontSize: "0.85em",
    padding: "10px",
    overflowY: "auto",
    height: "200px",
    marginBottom: "20px",
    "&::-webkit-scrollbar-thumb": {
      background: "#77FE39"
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(0,0,0,0.8)"
    },
    "&::-webkit-scrollbar": {
      width: "5px"
    }
  },
  changeRatingText: {
    display: "inline-block",
    padding: 0,
    marginTop: "10px",
    lineHeight: "10px",
    fontSize: "0.75rem"
  },
  iconsContainer: {
    margin: "0 auto",
    position: "relative",
    color: "white",
    backgroundColor: "transparent"
  },
}));

function PosterRecommendation(props) {
  const classes = useStyles(props);
  const [isActive, setActive] = useState(false);
  // const [poster, setPoster] = useState(errorImage);
  let { PosterURL, title, Rating, releaseYear, trailer, Length, Logline } = props.movie;
  // const { getSingleRec } = props;
  Length = Length ? Length : ""
  var runtime = Length ? Length : "";
  let runtimeMatch = Length.match(/(\d+) Hours? (\d+) Minutes?/i);
  if(runtimeMatch) {
    runtime = runtimeMatch[1] + "h " + runtimeMatch[2] + "m";
  }
  const errorHandler = (e) => {
    // setPoster(errorImage)
    // getSingleRec()
  }

  // useEffect(() => {
  //   if (PosterURL) {
  //     setPoster(PosterURL)
  //   }
  // }, [PosterURL]);

  return (
    <Grid className={classes.container}>
      <Grid container className={classes.posterContainer}
          onMouseEnter={() => setActive(true)}
          onMouseLeave={() => setActive(false)}
          >
        <CardMedia
          component="img"
          className={classes.poster}
          image={PosterURL}
          title={title}
          onError={errorHandler}
          alt="No Poster Available"
        />
        {isActive && (
          <div className={classes.backdrop}>
            <Grid item container xs={12} className={classes.details}>
              <Grid item className={classes.info}>
                <Grid item>
                  <Typography classes={{ body1: classes.title }}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography classes={{ body1: classes.text }}>
                    ({releaseYear})
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.links}>
                <Grid item>
                  <Typography classes={{ body1: classes.synopsis }}>
                    {Logline}
                  </Typography>
                </Grid>
                <Grid item>
                  <a rel="noopener noreferrer" href={trailer} target="_blank" style={{color: "white"}}>
                    <Theaters />
                    <Typography classes={{ body1: classes.linkText }}>
                      Watch Trailer
                    </Typography>
                  </a>
                </Grid>
              </Grid>
              <Grid item className={classes.rating}>
                <Grid item>
                  <Typography classes={{ body1: classes.text }}>
                    {Rating} &nbsp; | &nbsp; {runtime}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
        <RecRatingButtons movie={props.movie} setRecRating={props.setRecRating}/>
      {/* <Typography
        align="center"
        component="div"
        className={classes.iconsContainer}
      >
        <GroupRatingBar />
      </Typography> */}
    </Grid>
  );
}

export default pure(PosterRecommendation);
