import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import {
  Forward
} from "@material-ui/icons";

import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    color: "white",
    justifyContent: "space-evenly",
    width: "60%",
    marginTop: "20px",
    marginBottom: "20px"
  },
  icon: {
    fontSize: "1.2em",
    position: "relative",
  },
  square: {
    border: "2px solid green",
    borderColor: theme.colors.primaryGreen,
    padding: "1px 2px 0px 2px",
    marginRight: "5px",
    display: "inline-flex",
    height: "19px",
    width: "20px"
  },
  text: {
    fontSize: "0.70em"
  },
  space: {
    lineHeight: "18px",
    fontSize: "0.7em",
    paddingLeft: "34px",
    width: "70px",
    color: "white",
    border: "2px solid green",
    borderColor: theme.colors.primaryGreen,
    marginRight: "5px",
    display: "inline-flex",
    height: "20px",
  },
  item: {
    display: "flex",
    alignItems: "center"
  }
}));

export default function HelpBar(props) {
  const classes = useStyles(props);

  const ratings = [
    { icon: <Forward className={classes.icon} style={{transform: "rotate(270deg)"}} />, text: "It's Okay" },
    { icon: <Forward className={classes.icon} style={{transform: "rotate(180deg)"}} />, text: "Disliked It" },
    { icon: <Forward className={classes.icon} />, text: "Loved It" },
    { icon: <Forward className={classes.icon} />, text: "Liked It" },
    { icon: <Forward className={classes.icon} style={{transform: "rotate(90deg)"}} />, text: "Haven't Seen It" }
];

  const makeRatingBar = ratings => {
    return ratings.map(item => {
      if (item.text == "Loved It"){
        return (
          <Grid item className={classes.item} key={item.text}>
            <div className={classes.space}>Space</div>
            <span className={classes.text}>{item.text}</span>
          </Grid> 
        )
      } else {
        return (
          <Grid item className={classes.item} key={item.text}>
            <div className={classes.square}>{item.icon}</div>
            <span className={classes.text}>{item.text}</span>
          </Grid>
        )
      }

    });
  };

  return (
    <div className={classes.container}>
      {makeRatingBar(ratings)}
    </div>
  );
}
