import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
  Tooltip,
  Zoom,
} from "@material-ui/core";

import {
  Add,
  Favorite,
  CheckCircleRounded,
  VisibilityOff,
} from "@material-ui/icons";

const useStyles = makeStyles((props) => ({
  container: {
    background: "transparent",
    height: "25px",
    marginTop: "-12.5px",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  icon: {
    color: "white",
    fontSize: "1.5em",
    margin: "0 10px",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  dislike: {
    transform: "rotate(45deg)",
    "&:hover": {
      transform: "rotate(45deg) scale(1.2)",
    },
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.colors.primaryLightPurple,
    color: theme.colors.primaryGreen,
    fontSize: "1em",
  },
}))(Tooltip);

export default function GroupRatingBar(props) {
  const classes = useStyles(props);
  const AddIcon = <Add style="color: red" />;
  const icons = {
    dislike: AddIcon,
    loved: Favorite,
    like: CheckCircleRounded,
    unseen: VisibilityOff,
  };

  const partyUsers = [
    {
      name: "Kyle Haley",
      id: 0,
      isActive: true,
      rating: {
        seen: true,
        rating: "loved",
      },
    },
    {
      name: "Jacob C",
      id: 1,
      isActive: true,
      rating: {
        seen: false,
        rating: "unseen",
      },
    },
    {
      name: "Jeff Bridges",
      id: 2,
      isActive: true,
      rating: {
        seen: true,
        rating: "like",
      },
    },
    {
      name: "John Smith",
      id: 3,
      isActive: true,
      rating: {
        seen: true,
        rating: "dislike",
      },
    },
    {
      name: "Jimmy Neutron",
      id: 4,
      isActive: true,
      rating: {
        seen: false,
        rating: "unseen",
      },
    },
  ];

  return (
    <div className={classes.container}>
      {/*  add tooltip over hover to display name */}
      {partyUsers.map((user, index) => {
        // TODO: limit users to 7
        const ElementName = icons[user.rating.rating];
        if (user.rating.rating == "dislike") {
          return (
            <LightTooltip title={user.name && user.name} TransitionComponent={Zoom} className={classes.tooltip} key={user.id}>
              <Add
                className={classnames(classes.icon, classes.dislike)}
              />
            </LightTooltip>
          );
        } else {
          return (
            <LightTooltip title={user.name && user.name} TransitionComponent={Zoom} className={classes.tooltip} key={user.id}>
              <ElementName className={classes.icon} key={user.id}></ElementName>
            </LightTooltip>
          );
        }
      })}
    </div>
  );
}
