import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    margin: "0 auto",
  },
  poster: {
    display: "flex",
    objectFit: "fill",
    flex: "1",
    height: "570px",
    maxWidth: "100%",
    margin: "0 auto",
    alignItems: "center",
    // border: "1px solid lightgray",
  },
  text: {
    width: "100%",
    fontSize: "2em",
    color: "white"
  }
}));

function HistoryLoading(props) {
  const classes = useStyles(props);

  return (
    <Grid className={classes.container}>
        <Grid item style={{textAlign: "center"}}>
          <Typography align="center" className={classes.text} component="span">
            Please wait while we load your history...
          </Typography>
        </Grid>
    </Grid>
  );
}

export default HistoryLoading;
