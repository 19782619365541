import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Redirect, NavLink, useHistory as routerHistory} from "react-router-dom";
import { useUser } from "../context/UserContext";
import { useHistory } from "../context/HistoryContext";
import { useMovies } from "../context/MoviesContext";
import { useLoading } from "../context/LoadingContext";
import { useCurrentMovie } from "../context/CurrentContext";
import { useCount } from "../context/CountContext";

import {
  login,
  getCompleteUser,
  getRatingHistory,
  sendPasswordResetEmail,
  fetchUserMovies
} from "../utils/api";

import {
  Button,
  TextField,
  Typography,
  Grid,
  CircularProgress
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    // width: "55%",
  },
  form: {
    display: "flex",
    flex: "1",
    marginTop: "15px",
    alignItems: "center",
    justifyContent: "center"
  },
  fields: {
    width: "480px"
  },
  avatarContainer: {
    width: "auto",
    padding: "0px 30px"
  },
  textField: {
    color: "white",
    borderColor: "white",
    borderWidth: "10px",
    "&.focused": {
      color: "red"
    }
  },
  text: {
    fontSize: "1.2em",
    color: "white",
    borderColor: "#ced4da",
    marginBottom: "30px"
  },
  login: {
    fontSize: "0.75em",
    color: "white",
    backgroundColor: theme.colors.primaryDarkPurple,
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "47.5%",
    height: "60px",
    textTransform: "none",
    border: "2px solid white",
    "&:hover": {
      color: "white",
      backgroundColor: theme.colors.primaryDarkPurple,
      border: "2px solid white",
      borderColor: theme.colors.primaryGreen,
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#4B1684",
      borderColor: theme.colors.primaryGreen
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem #4B1684"
    }
  },
  signup: {
    fontSize: "0.75em",
    color: "white",
    backgroundColor: "transparent",
    fontFamily: "Samsung Sharp Sans Medium",
    outline: "none !important",
    letterSpacing: "1.5px",
    width: "220px",
    height: "60px",
    textTransform: "none",
    "&:hover": {
      color: theme.colors.primaryGreen,
      backgroundColor: "transparent",
      borderColor: "white",
      boxShadow: "none"
    }
  },
  forgotPassword: {
    display: "inline-block",
    fontSize: "0.75em",
    color: theme.colors.primaryGreen,
    borderBottom: "1px solid",
    borderColor: theme.colors.primaryGreen,
    padding: 0,
    lineHeight: "10px",
    cursor: "pointer"
  },
  error: {
    fontSize: "1em",
    color: "red",
    margin: "20px 0"
  },
  spinner: {
    color: theme.colors.primaryGreen
  }
}));

export default function MyAccount(props) {

  const classes = useStyles(props);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [credentials, setCredentials] = useState(
    {
    email: "",
    password: ""
  }
  );
  const [error, setError] = useState(null);
  const [resetMessage, setResetMessage] = useState("");
  const { user, setCurrentUser } = useUser();
  const { history, setCurrentHistory } = useHistory();
  const { loading, setLoading } = useLoading();
  const { setCurrentMovies } = useMovies();
  const { count, setCurrentCount } = useCount();
  const { currentMovie, setCurrentMovie } = useCurrentMovie();

  const handleLogin = async () => {
    if (credentials && credentials.email && credentials.password) {
      setLoggingIn(true);
      setError(null);
      const response = await login(credentials);
      if (response.error) {
        setLoggingIn(false);
        setError(response.error);
      } else if (response.user) {
        setCurrentMovies([]);
        setCurrentUser({
          email: response.user.email,
          uid: response.user.localId,
        });
        setLoading(true);
        setLoggedIn(true);
        const completeUserResponse = await getCompleteUser(response.user.localId);
        // const ratingsResponse = await handleFetchRatings(response.user.uid);
        if (completeUserResponse && completeUserResponse.user){
          setCurrentUser({
            name: completeUserResponse.user.data.Name,
            email: completeUserResponse.user.data.Email,
            uid: completeUserResponse.user.data.uid,
            zipcode: completeUserResponse.user.data.Zipcode,
            gender: completeUserResponse.user.data.Gender,
            age: completeUserResponse.user.data.Age,
            services: completeUserResponse.user.data.Services
          });
          setCurrentCount(completeUserResponse.user.data.rated_count);
        }
        const moviesResponse = await fetchUserMovies(response.user.localId);
        if (moviesResponse && moviesResponse.movies && moviesResponse.movies.length >= 1) {
          console.log("Setting movies to:", moviesResponse.movies)
          setCurrentMovies(moviesResponse.movies)
          setCurrentMovie(moviesResponse.movies[moviesResponse.movies.length - 1])
          setLoading(false)
        } else if (moviesResponse && moviesResponse.movies && moviesResponse.movies.length == 0){
          console.log("Setting movies to: empty")
          setCurrentMovies([])
          setCurrentMovie({})
          setLoading(false)
        }
      }
      return response;
    } else {
      setError("Please enter a valid email and password");
    }
  };

  const handleFetchRatings = async uid => {
    const response = await getRatingHistory(uid);
    setCurrentHistory({count: history.count + response.count, history: response.ratings})
  };

  const handlePasswordReset = async () => {
    setError(null);
    if (!credentials.email) {
      setError("Please enter an email to reset your password");
    } else {
      setResetMessage(null);
      const response = await sendPasswordResetEmail(credentials.email);
      setResetMessage(response);
    }
  };

  const handleSignup = () => (
    <Redirect to="/signup" />
  );

  const handleChange = event => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  const enterKeyPress = (e) => {
    if (e.keyCode == 13){
      handleLogin()
   }
  }

  // can use this with router history to redirect to last page
  // if user sees a LOGIN button on multiple pages
  const referer = "/rate";
  

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <Grid container className={classes.container} justify={"center"}>
        <Grid item container className={classes.fields}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              label="Email"
              id="email"
              name="email"
              onKeyDown={enterKeyPress}
              onChange={handleChange}
              fullWidth
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              id="password"
              name="password"
              type="password"
              fullWidth
              onKeyDown={enterKeyPress}
              onChange={handleChange}
              InputProps={{
                className: classes.text
              }}
              InputLabelProps={{ className: classes.textField }}
            />
          </Grid>
          <Grid container justify="center">
            <Typography
              component="span"
              classes={{ body1: classes.forgotPassword }}
              onClick={handlePasswordReset}
            >
              Forgot password?
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Typography component="span" classes={{ body1: classes.error }}>
              {error}
            </Typography>
            <Typography component="span" classes={{ body1: classes.error }}>
              {resetMessage}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ display: "flex", paddingTop: "25px" }}
            justify="center"
          >
            <Button
              className={classes.login}
              variant="contained"
              disableRipple
              disableElevation
              type="button"
              disabled={isLoggingIn ? true : false}
              onClick={handleLogin}
            >
              {!isLoggingIn ? (
                "Login"
              ) : (
                <CircularProgress className={classes.spinner} />
              )}
            </Button>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ display: "flex", paddingTop: "25px" }}
            justify="center"
          >
            <NavLink to="/signup">
              <Button
                className={classes.signup}
                variant="contained"
                disableRipple
                disableElevation
                type="button"
                onClick={handleSignup}
              >
                Need an account? Signup!
              </Button>
            </NavLink>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
