import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useLocation } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    background: "transparent",
    marginBottom: "10px",
  },
  fixed:{
    width: "85%",
    position: "fixed",
    background: theme.colors.primaryLightPurple,
    top: "120px"
  },
  toolbar: {
    flexWrap: "wrap",
  },
  nav: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-start",
  },
  link: {
    width: "auto",
    padding: "5px 0",
    fontSize: "2em",
    color: "silver !important",
    cursor: "pointer",
    letterSpacing: "2px",
    borderBottom: "2px solid transparent",
    marginRight: "1.1em",
    fontFamily: "Samsung Sharp Sans Regular, sans-serif !important",
    "&:hover": {
      color: "white !important",
      borderBottom: "2px solid rgb(119, 254, 57, 0.75)",
    },
    "&.activeLink": {
      color: "white !important",
      borderBottom: "2px solid rgb(119, 254, 57, 0.75)",
    }
  },
  "@media only screen and (max-width: 1270px)": {
    link: {
      fontSize: "1.75em"
    }
  },
  "@media only screen and (max-width: 1125px)": {
    link: {
      fontSize: "1.5em"
    }
  }
}));

export default function InlineMenu(props) {
  const classes = useStyles(props);
  let location = useLocation();

  return (
    <AppBar position="relative" className={classes.container} elevation={0}>
    {/* <AppBar position="relative" className={location.pathname == "/history" ? classes.fixed : classes.container} elevation={0}> */}
      <Toolbar className={classes.toolbar}  disableGutters>
        <nav className={classes.nav}>
          <Typography
            variant="h1"
            align="center"
            component={NavLink}
            className={classes.link}
            to="/rate"
            activeClassName="activeLink"
          >
            Rate Movies
          </Typography>
          <Typography
            variant="h1"
            id="rec"
            align="center"
            component={NavLink}
            className={classes.link}
            to="/recommendations"
            activeClassName="activeLink"
          >
            {/* make it us, you, me */}
            Just For You
          </Typography>
          <Typography
            variant="h1"
            align="center"
            component={NavLink}
            className={classes.link}
            to="/history"
            activeClassName="activeLink"
          >
            Rating History
          </Typography>
          <Typography
            variant="h1"
            align="center"
            component={NavLink}
            className={classes.link}
            to="/account"
            activeClassName="activeLink"
          >
            My Account
          </Typography>
        </nav>
      </Toolbar>
    </AppBar>
  );
}
